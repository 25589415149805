<div class="searchbox-container">
    <ng-select
        class="searchbox"
        [searchWhileComposing]="false"
        [formControl]="searchCtrl"
        groupBy="Type"
        [items]="searchResult$ | async"
        bindLabel="ShowName"
        (change)="openDeviceAssets($event)"
        (close)="closeSearch()"
        placeholder="Search edge device or assets"
        [minTermLength]="3"
        [typeahead]="typeaheadSubject"
        typeToSearchText="Requires more than 2 characters"
        (search)="checkInput($event)"
        [notFoundText]="'No data found'"
        #appSearch
    >
        <ng-template ng-optgroup-tmp let-item="item">
            {{ item.Type }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
            <div class="text-search-suggestion" [ngbTooltip]="item.ShowName" placement="auto" openDelay="0" class="text-truncate">{{ item.ShowName }}</div>
        </ng-template>
    </ng-select>
    <i class="fas fa-search fa-sm"></i>
</div>
