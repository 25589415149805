import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const protectedResources = {
    connectPortalAPI: {
        endpoint: environment.AzureActiveDirectory_B2C_AngularConfig.applicationURL,
        scopes: {
            read: [environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ConsentScopes.connectPortalAPI],
            write: [environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ConsentScopes.connectPortalAPI],
        },
    },
};

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ClientId,
        authority: environment.AzureActiveDirectory_B2C_AngularConfig.b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [environment.AzureActiveDirectory_B2C_AngularConfig.b2cPolicies.authorityDomain],
        redirectUri: environment.AzureActiveDirectory_B2C_AngularConfig.redirectUri,
        postLogoutRedirectUri: environment.AzureActiveDirectory_B2C_AngularConfig.AAD_PostLogoutRedirectUri,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
};

export const loginRequest = {
    scopes: [environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ConsentScopes.connectPortalAPI, environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ConsentScopes.openId],
};
