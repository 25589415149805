import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { AuthorizationGuard } from './authorization/authorization.guard';
import { ROUTER_PERMISSIONS } from './authorization/router-permissions';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

export const routes: Routes = [
    {
        path: 'portal',
        component: MsalRedirectComponent,
    },
    {
        path: 'tenant',
        loadChildren: () => import('./tenant/tenant.module').then((m) => m.TenantModule),
        data: { permissions: [ROUTER_PERMISSIONS.tenant] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'tenant/administration',
        loadChildren: () => import('./tenant/tenant-administration/tenant-administration.module').then((m) => m.TenantAdministrationModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'dashboards',
        loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
    },
    {
        path: 'devices',
        loadChildren: () => import('./overviews/devices-overview/devices-overview.module').then((m) => m.DevicesOverviewModule),
        data: { permissions: [ROUTER_PERMISSIONS.edgeDevice] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'devices/:id',
        loadChildren: () => import('./device-details/device-details.module').then((m) => m.DeviceDetailsModule),
    },
    {
        path: 'asset-groups/:assetGroupId/assets',
        loadChildren: () => import('./asset-details/asset-details.module').then((m) => m.AssetDetailsModule),
        data: { permissions: [ROUTER_PERMISSIONS.asset] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'devices/:id/assets',
        loadChildren: () => import('./asset-details/asset-details.module').then((m) => m.AssetDetailsModule),
        data: { permissions: [ROUTER_PERMISSIONS.asset] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'devices/:deviceId/workflows',
        loadChildren: () => import('./workflow/workflow.module').then((m) => m.WorkflowModule),
    },
    {
        path: 'devices/:deviceId/analytics',
        loadChildren: () => import('./analytics-details/analytics-details.module').then((m) => m.AnalyticsDetailsModule),
        data: { permissions: [ROUTER_PERMISSIONS.analytics] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'asset-groups/:assetGroupId/analytics',
        loadChildren: () => import('./analytics-details/analytics-details.module').then((m) => m.AnalyticsDetailsModule),
        data: { permissions: [ROUTER_PERMISSIONS.analytics] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'assets',
        loadChildren: () => import('./overviews/assets-overview/assets-overview.module').then((m) => m.AssetsOverviewModule),
        data: { permissions: [ROUTER_PERMISSIONS.asset] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'asset-groups',
        loadChildren: () => import('./asset-groups/asset-groups.module').then((m) => m.AssetGroupsModule),
        data: { permissions: [ROUTER_PERMISSIONS.asset] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'workflows',
        loadChildren: () => import('./overviews/workflows-overview/workflows-overview.module').then((m) => m.WorkflowsOverviewModule),
        data: { permissions: [ROUTER_PERMISSIONS.workflow] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'workflows/modules',
        loadChildren: () => import('./workflow/workflow-modules-overview/workflow-modules-overview.module').then((m) => m.WorkflowModulesOverviewModule),
        data: { permissions: [ROUTER_PERMISSIONS.workflowModules] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'export',
        loadChildren: () => import('./data-export/data-export.module').then((m) => m.DataExportModule),
        data: { permissions: [ROUTER_PERMISSIONS.dataExport] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'alerts',
        loadChildren: () => import('./alerts/alerts.module').then((m) => m.AlertsModule),
        data: { permissions: [ROUTER_PERMISSIONS.alert] },
        canActivate: [AuthorizationGuard],
    },
    {
        path: 'device/register',
        loadChildren: () => import('./edge-device-registration/edge-device-registration.module').then((m) => m.EdgeDeviceRegistrationModule),
        data: { permissions: [ROUTER_PERMISSIONS.provisionEdgeDevice] },
        canActivate: [AuthorizationGuard],
        pathMatch: 'full',
    },
    {
        path: 'not-found',
        component: NotFoundComponent,
        canActivate: [MsalGuard],
    },
    {
        path: 'not-authorized',
        component: NotAuthorizedComponent,
        canActivate: [MsalGuard],
    },
    {
        path: 'disclaimer',
        component: DisclaimerComponent,
        canActivate: [MsalGuard],
    },
    {
        path: '',
        redirectTo: 'tenant',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
            bindToComponentInputs: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
