import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs';
import { ApplicationState } from '../../state/application.state';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
    private permissions_: Set<string>;

    public get permissions() {
        return this.permissions_;
    }

    constructor(private store: Store) {
        // permission service is created once
        this.store
            .select(ApplicationState.permissions)
            .pipe(filter((permissions) => permissions != null))
            // eslint-disable-next-line rxjs/no-ignored-subscription
            .subscribe((permissions) => {
                const set = Object.keys(permissions)
                    .map((key: keyof typeof permissions) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const permission: any = permissions[key];
                        return permission.scopes.map((s: string) => `${key}.${s}`.toLowerCase());
                    })
                    .reduce((a, b) => a.concat(b));

                this.permissions_ = new Set<string>(set);
            });
    }

    public hasPermissions(permissions: string[]) {
        const hasPermissions = permissions.map((p) => this.permissions?.has(p.toLowerCase()) === true);
        return hasPermissions?.every((p) => p) && hasPermissions?.length > 0;
    }
}
