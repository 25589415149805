import { NotificationsService, PageResult, TenantNotificationItemsResult } from '@api';
import { StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { INotificationsStateModel } from '../notifications.state.model';
import { ReadAllNotifications } from './read-all-notifications.actions';

export const readAllNotifications = ({ patchState }: StateContext<INotificationsStateModel>, _: ReadAllNotifications, store: Store, notificationsService: NotificationsService) => {
    const tenantName = store.snapshot().application.tenantName;
    return notificationsService.readAllNotification(tenantName).pipe(
        tap(() => {
            patchState({ notifications: { items: [], pages: { currentPage: 0, size: 0, totalItems: 0, totalPages: 0 } as PageResult } as TenantNotificationItemsResult });
        })
    );
};
