import { NotificationsService } from '@api';
import { StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { INotificationsStateModel } from '../notifications.state.model';
import { GetNotifications } from './get-notifications.actions';

export const getNotifications = ({ patchState }: StateContext<INotificationsStateModel>, action: GetNotifications, store: Store, notificationsService: NotificationsService) => {
    const tenantName = store.snapshot().application.tenantName;
    return notificationsService.getNotifications(tenantName, null, null, null, action.page, action.size, action.notificationOwner, action.notificationSeverity, action.notificationType).pipe(
        tap((notifications) => {
            patchState({ notifications });
        })
    );
};
