import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { NotificationsService } from './notifications-service/notifications.service';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
    private requestCount = 0;

    constructor(
        private notificationsService: NotificationsService,
        private loadingService: LoadingService
    ) {}

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestCount++;
        this.loadingService.isLoading.next(true);

        const accept = request.headers.get('Accept');
        if (accept === 'application/zip') {
            request = request.clone({
                responseType: 'blob',
            });
        }

        return next.handle(request).pipe(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            map((event: HttpEvent<any>) => {
                if (event.type !== HttpEventType.Response) {
                    return;
                }

                this.removeRequest();

                if (event instanceof HttpErrorResponse) {
                    if (event.status === 403) {
                        this.notificationsService.error('You are unauthorized to access this resource.');
                    }
                }

                return event;
            }),
            catchError((err) => {
                this.removeRequest();
                return throwError(err);
            })
        );
    }

    private removeRequest() {
        this.requestCount--;

        if (this.requestCount < 0) {
            this.requestCount = 0;
        }

        this.loadingService.isLoading.next(this.requestCount > 0);
    }
}
