import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { notificationSeverity } from '../notifications.repository';
import { notificationType } from './../notifications.repository';
import { IFilterCriterias } from './filter-criterias.interface';

@Component({
    selector: 'app-notifications-filter',
    templateUrl: './notifications-filter.component.html',
    styleUrls: ['./notifications-filter.component.scss'],
})
export class NotificationsFilterComponent implements OnInit {
    @Output() public filterChanged = new EventEmitter<IFilterCriterias>();

    public form: UntypedFormGroup;
    public notificationSeverity = notificationSeverity;
    public notificationType = notificationType;
    public selectedType: string[] = [];

    constructor(fb: UntypedFormBuilder) {
        this.form = fb.group({
            notificationOwner: null,
            notificationSeverity: null,
            notificationType: null,
        });
    }

    public ngOnInit() {
        this.form.reset();
    }

    public onFilterChanged() {
        const formValues = this.form.value;
        this.filterChanged.emit(formValues);
    }
}
