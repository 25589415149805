import { StorageEngine } from '@ngxs/storage-plugin';
import { tryParseJSON } from '../extensions/string.extensions';

export abstract class StorageEngineBase implements StorageEngine {
    public length: number;

    public abstract getItem(key: string): string;
    public abstract setItem(key: string, value: unknown): void;

    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        // Do nothing
    }

    protected getSavedItems<T>(key: string) {
        const shouldAddItem = localStorage.getItem(key) == null;
        if (shouldAddItem) {
            localStorage.setItem(key, JSON.stringify({}));
        }

        const existingItemsInLocalStorage = localStorage.getItem(key);
        const items = tryParseJSON(existingItemsInLocalStorage, {}) as T;

        return items;
    }
}
