import { Injectable } from '@angular/core';
import { ActiveToast, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { ProblemDetails } from '../api';
import { LoggingService } from '../logging.service';
import { CustomToastComponent } from './custom-toastr/custom-toast.component';
import { IToastButton } from './custom-toastr/toast-button';
import { ToastrType } from './custom-toastr/toastr-type';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    constructor(
        private toastrService: ToastrService,
        private loggingService: LoggingService
    ) {
        this.toastrService.toastrConfig.enableHtml = true;
        this.toastrService.toastrConfig.timeOut = 10_000;
        this.toastrService.toastrConfig.extendedTimeOut = 10_000;
        this.toastrService.toastrConfig.progressBar = true;
        this.toastrService.toastrConfig.closeButton = true;
        this.toastrService.toastrConfig.tapToDismiss = false;
        this.toastrService.toastrConfig.preventDuplicates = true;
        this.toastrService.toastrConfig.positionClass;
    }

    public set notificationContainer(toastContainer: ToastContainerDirective) {
        this.toastrService.overlayContainer = toastContainer;
    }

    public success(message: string, title?: string) {
        this.toastrService.success(message, title);
    }

    public info(message: string, title?: string) {
        this.toastrService.info(message, title);
    }

    public warning(message: string, title?: string, warnings?: string[]) {
        let toastMessage = `<div class="notification-message">${message}</div>`;

        if (warnings?.length) {
            toastMessage += '<ul class="validation-warning-messages">';

            for (const warning of warnings) {
                toastMessage += `<li class="validation-warning-message">${warning}</li>`;
            }

            toastMessage += '</ul>';
        }

        this.toastrService.warning(toastMessage, title);
        this.loggingService.warn(message, warnings);
    }

    public error(message: string, problemDetails?: ProblemDetails) {
        let toastMessage = `<div class="notification-message">${message}</div>`;

        toastMessage = this.getValidationDetails(problemDetails, toastMessage);

        const correlationId = problemDetails?.extensions?.correlationId;
        if (correlationId != null) {
            toastMessage += `<div class="correlation-id">Correlation ID: ${correlationId}</div>`;
        }

        this.toastrService.error(toastMessage, problemDetails?.title);
        this.loggingService.error(message, problemDetails);
    }

    private getValidationDetails(problemDetails: ProblemDetails, toastMessage: string) {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const errors = (problemDetails as any)?.errors as { [index: number]: { message: string } };

        if (errors != null) {
            const validationErrorMessages = Object.values(errors).map((c) => (c.message == null ? Object.values(c) : c.message));

            // if we only have one error message, we display it directly without a bullet list
            if (validationErrorMessages.length === 1) {
                toastMessage += `<div class="notification-error">${validationErrorMessages[0].toString()}</div>`;
                return toastMessage;
            }

            // display all error messages
            toastMessage += '<ul class="validation-error-messages">';

            for (const validationErrorMessage of validationErrorMessages) {
                toastMessage += `<li class="validation-error-message">${validationErrorMessage}</li>`;
            }
            toastMessage += '</ul>';
        } else if (problemDetails?.detail != null) {
            toastMessage += `<div class="notification-error">${problemDetails.detail.replace('\n', '</br>')}</div>`;
        }

        return toastMessage;
    }

    public showCustomToastr(message: string, title?: string, buttons?: IToastButton[], toastrType = ToastrType.warning) {
        const individualConfig = {
            ...this.toastrService.toastrConfig,
            disableTimeOut: true,
            toastComponent: CustomToastComponent,
        };

        let activeToast: ActiveToast<CustomToastComponent> = null;

        switch (toastrType) {
            case ToastrType.error:
                activeToast = this.toastrService.error(message, title, individualConfig);
                break;
            case ToastrType.info:
                activeToast = this.toastrService.info(message, title, individualConfig);
                break;
            case ToastrType.success:
                activeToast = this.toastrService.success(message, title, individualConfig);
                break;
            case ToastrType.warning:
                activeToast = this.toastrService.warning(message, title, individualConfig);
                break;
        }

        activeToast.toastRef.componentInstance.buttons = buttons;
        return activeToast;
    }
}
