<app-portal-header></app-portal-header>

<div class="content disclaimer-container">
    <h3>Secure Disclaimer</h3>
    <div class="disclaimer-message">You should not use achtBytes IoT in security sensitive applications because it can not be guaranteed that the application prevents harm done.</div>
    <div class="mt-3 mb-3"><input type="checkbox" [checked]="hasAccepted" (click)="acceptDisclaimer()" /> I consent to the given terms.</div>
    <hr />
    <div>
        <button type="button" class="btn btn-primary" (click)="confirmAcceptedDisclaimer()" [disabled]="!hasAccepted">Save</button>
    </div>
</div>
