import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IDeactivationGuard {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class DeactivationGuard {
    public canDeactivate(component: IDeactivationGuard) {
        try {
            return component.canDeactivate ? component.canDeactivate() : true;
        } catch {
            // if canDeactivate crashes at least allow navigation.
            return true;
        }
    }
}
