import { StorageEngineBase } from 'src/app/shared/classes/storage-engine-base';
import { tryParseJSON } from 'src/app/shared/extensions/string.extensions';
import { ITelemetryChartPreferences } from 'src/app/shared/interfaces/telemetry-chart-preferences';

export class TelemetryChartPreferencesCustomStorage extends StorageEngineBase {
    public getItem(key: string) {
        const preferences = this.getSavedItems<ITelemetryChartPreferences>(key);
        return JSON.stringify(preferences);
    }

    public setItem(key: string, value: unknown): void {
        const lastPreferences = tryParseJSON(value?.toString(), {}) as ITelemetryChartPreferences;
        if (Object.keys(lastPreferences).length === 0) {
            return;
        }

        const preferences = this.getSavedItems<ITelemetryChartPreferences>(key);
        const preferencesToSave = { ...preferences, ...lastPreferences };
        localStorage.setItem(key, JSON.stringify(preferencesToSave));
    }
}
