import { TenantNotificationSeverity } from '@api/model/tenantNotificationSeverity';

export class GetNotifications {
    public static readonly type = '[Notifications] Get notifications';
    constructor(
        public notificationOwner: boolean = false,
        public notificationSeverity: TenantNotificationSeverity[] = null,
        public notificationType: string[] = null,
        public page: number = 1,
        public size: number = 5
    ) {}
}
