import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService implements OnDestroy {
    private isLoadingSubscription: Subscription;

    public isLoading = new BehaviorSubject(false);

    constructor() {
        this.isLoadingSubscription = this.isLoading.subscribe((loading) => {
            window.isLoading = loading;
        });
    }

    public ngOnDestroy(): void {
        this.isLoadingSubscription.unsubscribe();
    }
}

declare global {
    interface Window {
        isLoading: boolean;
    }
}
