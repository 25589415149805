import { Component, OnInit } from '@angular/core';
import { ProblemDetails, TenantNotificationItemsResult, TenantNotificationSeverity } from '@api';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tryParseJSON } from '../../extensions/string.extensions';
import { ApplicationState } from '../../state/application.state';
import { GetNotifications } from '../../state/notifications-state/get-notifications/get-notifications.actions';
import { NotificationsSelectors } from '../../state/notifications-state/notifications.selectors';
import { PushNotifications } from '../../state/notifications-state/push-notifications/push-notifications.actions';
import { ReadAllNotifications } from '../../state/notifications-state/read-all-notifications/read-all-notifications.actions';
import { ReadNotification } from '../../state/notifications-state/read-notification/read-notification.actions';
import { ComponentBaseDirective, IAllowRealTimeUpdates } from '../component.base';
import { ComponentBaseService } from '../component.base.service';
import { IFilterCriterias } from './notifications-filter/filter-criterias.interface';
import { notificationTypes } from './notifications.repository';

type MessageParameters = { [key: string]: string | number | boolean };

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends ComponentBaseDirective implements OnInit, IAllowRealTimeUpdates {
    private readonly _messageParametersRegex = /\{\{\s*([^\}\s]+)\s*\}\}/gi;
    private tenantName: string;
    public isFilteringEnabled = false;
    public TenantNotificationSeverity = TenantNotificationSeverity;
    public filterCriterias = { notificationOwner: null, notificationSeverity: null, notificationType: null } as IFilterCriterias;

    @Select(NotificationsSelectors.notifications) public notifications$: Observable<TenantNotificationItemsResult>;

    constructor(componentBaseService: ComponentBaseService) {
        super(componentBaseService);
    }

    public ngOnInit() {
        this.tenantName = this.store.selectSnapshot(ApplicationState.tenantName);

        this.addLoader('notifications-loader', GetNotifications, ReadNotification, ReadAllNotifications);

        this.ofActionSuccessful(() => this.store.dispatch(new GetNotifications()), ReadNotification);

        this.ofActionDispatched((action: PushNotifications) => this.pushNotifications(action), PushNotifications);

        this.store.dispatch(new GetNotifications());

        this.startRealTimeUpdates();
    }

    public getNotificationTypeTitle(notificationType: keyof typeof notificationTypes, messageParameters: string) {
        return this.replaceMessageParameters(notificationTypes[notificationType]?.title ?? 'unknown', tryParseJSON(messageParameters));
    }

    public getNotificationTypeText(notificationType: keyof typeof notificationTypes, messageParameters: string) {
        return this.replaceMessageParameters(notificationTypes[notificationType]?.text ?? 'unknown', tryParseJSON(messageParameters));
    }

    public readNotification(notificationId: string) {
        this.store.dispatch(new ReadNotification(notificationId));
    }

    public markAllAsRead() {
        this.store.dispatch(new ReadAllNotifications());
    }

    public filterNotifications(values: IFilterCriterias) {
        this.filterCriterias = values;
        this.store.dispatch(new GetNotifications(values.notificationOwner, values.notificationSeverity, values.notificationType));
    }

    public toggleFilterContainer() {
        this.isFilteringEnabled = !this.isFilteringEnabled;
        if (!this.isFilteringEnabled) {
            this.filterCriterias = null;
            this.store.dispatch(new GetNotifications());
        }
    }

    public changePage(page: number) {
        this.store.dispatch(new GetNotifications(this.filterCriterias.notificationOwner, this.filterCriterias.notificationSeverity, this.filterCriterias.notificationType, page));
    }

    public async startRealTimeUpdates() {
        await this.startRealTime(this.tenantName, 'Notifications', PushNotifications);
    }

    public async stopRealTimeUpdates() {
        await this.stopRealTime(this.tenantName, 'Notifications', PushNotifications);
    }

    public replaceMessageParameters(text: string, messageParameters: MessageParameters) {
        return text.replace(this._messageParametersRegex, (_, groupValue) => String(messageParameters?.[groupValue] ?? ''));
    }

    private pushNotifications(action: PushNotifications) {
        for (const notification of action.notifications) {
            const notificationType = notification.notificationType as keyof typeof notificationTypes;
            const title = this.getNotificationTypeTitle(notificationType, notification.messageParameters);
            const text = this.getNotificationTypeText(notificationType, notification.messageParameters);

            if (notification.severity === TenantNotificationSeverity.NUMBER_0) {
                this.notificationsService.info(text, title);
            } else if (notification.severity === TenantNotificationSeverity.NUMBER_1) {
                this.notificationsService.warning(text, title);
            } else if (notification.severity === TenantNotificationSeverity.NUMBER_2) {
                this.notificationsService.error(text, { title } as ProblemDetails);
            }
        }
    }
}
