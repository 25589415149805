/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { symbols } from './keys';
import { Shortcut } from './ng-keyboard-shortcuts.interfaces';
import { identity } from './utils';

/**
 * @ignore
 */
@Component({
    selector: 'ng-keyboard-shortcuts-help-item',
    templateUrl: './ng-keyboard-shortcuts-help-item.component.html',
    styleUrls: ['./ng-keyboard-shortcuts-help-item.component.css'],
})
export class KeyboardShortcutsHelpItemComponent implements OnInit {
    public parsedKeys: string[][];

    @Input() public index: number;

    @Input()
    public set shortcut(shortcut: Shortcut) {
        const key = Array.isArray(shortcut.key) ? shortcut.key : [shortcut.key];
        this.parsedKeys = key.map((key) =>
            key
                .split(' ')
                .filter(identity)
                .filter((key) => key !== '+')
                .map((key: keyof typeof symbols) => {
                    if (symbols[key]) {
                        return symbols[key];
                    }
                    return key;
                })
        );
        this._shortcut = shortcut;
    }
    public get shortcut() {
        return this._shortcut;
    }

    private _shortcut: Shortcut;

    constructor() {}

    public ngOnInit() {}
}
