/**
 * achtBytes IoT API
 * The achtBytes IoT API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@achtbytes.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type EntityValidationSeverity = 0 | 1 | 2;

export const EntityValidationSeverity = {
    INFO: 0 as EntityValidationSeverity,
    WARNING: 1 as EntityValidationSeverity,
    ERROR: 2 as EntityValidationSeverity,
};
