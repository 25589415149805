/* All constructors needed for app */
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { catchError, combineLatest, delay, filter, map, of, tap, throwError } from 'rxjs';
import { TENANT_ID } from './authorization/authorization-factories';
import { AuthorizationService } from './authorization/authorization.service';
import { ServiceWorkerUpdateService } from './service-worker-update.service';
import { ComponentBaseDirective } from './shared/components/component.base';
import { ComponentBaseService } from './shared/components/component.base.service';
import { KeyboardShortcutsHelpComponent } from './shared/components/ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts-help.component';
import { ShortcutInput } from './shared/components/ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts.interfaces';
import { KeyboardShortcutsService } from './shared/components/ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts.service';
import { HelpGuideService } from './shared/services/help-guide.service';
import { LoggingService } from './shared/services/logging.service';
import { UserLoggedIn } from './shared/state/application.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [ServiceWorkerUpdateService],
})
export class AppComponent extends ComponentBaseDirective implements OnInit, OnDestroy {
    public shortcuts: ShortcutInput[] = [];
    public authorizationService: AuthorizationService = inject(AuthorizationService);
    public tenantId: string = inject(TENANT_ID);

    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    private logging: LoggingService = inject(LoggingService);
    private keyboardShortcutsService: KeyboardShortcutsService = inject(KeyboardShortcutsService);
    private helpGuideService: HelpGuideService = inject(HelpGuideService);
    private ngbTooltipConfig: NgbTooltipConfig = inject(NgbTooltipConfig);
    private authService: MsalService = inject(MsalService);
    private msalBroadcastService: MsalBroadcastService = inject(MsalBroadcastService);
    private titleService: Title = inject(Title);
    private updateService = inject(ServiceWorkerUpdateService);

    @ViewChild(KeyboardShortcutsHelpComponent) private shortcutHelpComponent: KeyboardShortcutsHelpComponent;

    public get isIframe(): boolean {
        return window !== window.parent && !window.opener;
    }

    public get isNotFoundPage() {
        return window.location.href.indexOf('not-found') !== -1;
    }

    public displayPage$ = this.authorizationService.hasPermissions$.pipe(map((hasPermissions) => hasPermissions || this.isNotFoundPage));

    constructor(public componentBaseService: ComponentBaseService) {
        super(componentBaseService);
    }

    private configureTooltipDefaults(): void {
        this.ngbTooltipConfig.container = 'body';
        this.ngbTooltipConfig.openDelay = 300;
    }

    public async ngOnInit() {
        this.addLoader('master', UserLoggedIn);

        if (!this.isNotFoundPage) {
            this.loaderService.startLoader('master');
        }

        this.logging.trace(`Is Iframe? ${this.isIframe}`);

        if (this.isIframe) {
            return;
        }

        this.configureTooltipDefaults();

        this.authorizationService.subscribeLoginInProgress();

        this.subscribeMSALSubject();

        this.registerKeyboardService(this.keyboardShortcutsService, this.changeDetectorRef);

        this.subscription.add(
            combineLatest([this.displayPage$, this.authorizationService.isLoginInProgress$])
                .pipe(
                    filter(([displayPage, isLoginInProgress]) => displayPage && !isLoginInProgress),
                    delay(0),
                    tap(() => {
                        this.registerShortcutHelp(this.shortcutHelpComponent);
                        this.changeDetectorRef.detectChanges();

                        this.setWindowsTitle();
                    })
                )
                .subscribe()
        );
    }

    private subscribeMSALSubject() {
        this.subscription.add(
            this.authService
                .handleRedirectObservable()
                .pipe(
                    catchError((error: Error) => {
                        if (error.message.includes('Guard - error while logging in, unable to activate')) {
                            this.authorizationService.handleAcquireToken();
                            return of();
                        }

                        return throwError(() => error);
                    })
                )
                .subscribe()
        );

        this.subscription.add(
            this.msalBroadcastService.msalSubject$
                .pipe(filter((eventMessage: EventMessage) => eventMessage.eventType === EventType.LOGIN_SUCCESS || eventMessage.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || eventMessage.eventType === EventType.SSO_SILENT_SUCCESS))
                .subscribe((eventMessage: EventMessage) => {
                    this.authorizationService.handleRedirectAfterLogin(eventMessage);
                })
        );

        this.subscription.add(
            this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE)).subscribe((result: EventMessage) => {
                this.authorizationService.handleRedirectPasswordReset(result);
            })
        );
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'F1') {
            event.preventDefault();
            this.shortcutHelpComponent.toggle();
        } else if (event.key === 'F10') {
            event.preventDefault();
            this.helpGuideService.start();
        }
    }

    private setWindowsTitle() {
        const regExp = new RegExp('iot-([A-Za-z]+)\\.', 'gi');
        const environment = regExp.exec(window.location.host)?.[1];
        let title = this.titleService.getTitle();

        if (environment) {
            title += ` (${environment})`;
        }

        this.titleService.setTitle(title);
    }
}
