<div #modalWrapper>
    <div class="modal-header" [class.confirmation-modal-warning-bg-color]="isWarningDialog" [class.confirmation-modal-default-bg-color]="!isWarningDialog">
        <h4 class="modal-title text-uppercase" id="modal-title">{{ title }}</h4>
        <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body align-items-center">
        <div class="row">
            @if (isWarningDialog) {
                <div class="col-2 me-3 confirmation-modal-warning-icon-opacity">
                    <li class="fas fa-4x fa-exclamation-triangle confirmation-modal-warning-color"></li>
                </div>
            }
            <div class="col">
                <p class="m-0" [innerHTML]="message"></p>

                @if (deleteReferencedEntities?.length) {
                    <div class="mt-2 pt-1">
                        <hr class="m-0" />
                        <table class="table-striped w-100 mt-1">
                            <thead>
                                <tr>
                                    <th scope="col" class="p-1 w-20px"></th>
                                    <th scope="col" class="p-1">Name</th>
                                    <th scope="col" class="p-1">Entity</th>
                                </tr>
                            </thead>
                            <tbody>
                                @for (entity of deleteReferencedEntities; track entity) {
                                    <tr class="entityConflict">
                                        <td class="p-1">
                                            <app-error-list [errors]="getReferencedEntityWarnings(entity?.warnings)"></app-error-list>
                                        </td>
                                        <td class="p-1 text-truncate confirmation-modal-col-links-width name">
                                            <a [href]="entity.link" target="_blank" rel="noopener noreferrer nofollow">{{ entity.name }}</a>
                                        </td>
                                        <td class="p-1 type">{{ getReferencedEntityName(entity.type) }}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    </div>

    <div class="modal-footer">
        @for (confirmationButton of confirmationButtons; track confirmationButton) {
            <button
                *ngLet="confirmationButton.remainingSeconds$ | async as remainingSeconds"
                type="button"
                [id]="confirmationButton.id"
                [disabled]="confirmationButton.disabled || remainingSeconds > 0"
                [ngClass]="confirmationButton.ngClass"
                (click)="confirmationButton.click()"
            >
                {{ confirmationButton.title }}
                @if (remainingSeconds > 0) {
                    <span>({{ remainingSeconds }} sec.)</span>
                }
            </button>
        }
    </div>
</div>

<ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
