const authority = '__InstanceUrl__/__TenantUrl__/';
const signUpSignInPolicy = 'b2c_1a_signup_signin';
const resetPasswordPolicy = 'b2c_1_passwordreset';
const applicationURL = '__ApplicationUrl__';
const authorityDomain = '__AuthorityDomain__';

const AAD_B2C_Policies = {
    names: {
        signUpSignIn: signUpSignInPolicy,
        resetPassword: resetPasswordPolicy,
    },
    authorities: {
        signUpSignIn: {
            authority: `${authority}/${signUpSignInPolicy}`,
        },
        resetPassword: {
            authority: `${authority}/${resetPasswordPolicy}`,
        },
    },
    authorityDomain: authorityDomain,
};

export const environment = {
    production: true,
    basePath: applicationURL,
    AzureActiveDirectory_B2C_AngularConfig: {
        AAD_ClientId: '__ClientId__',
        AAD_Authority: authority,
        AAD_PostLogoutRedirectUri: applicationURL,
        AAD_ConsentScopes: {
            connectPortalAPI: 'https://__TenantUrl__/api-access',
            openId: 'openid',
        },
        knownAuthorities: [AAD_B2C_Policies.authorityDomain],
        signUpSignInPolicy: signUpSignInPolicy,
        resetPasswordPolicy: resetPasswordPolicy,
        applicationURL: applicationURL,
        authorityDomain: authorityDomain,
        redirectUri: applicationURL + '/portal',
        b2cPolicies: AAD_B2C_Policies,
    },
    instrumentationKey: '__InstrumentationKey__',
    logLevel: 'info',
    version: {
        environment: '__VersionEnvironment__',
        number: '__VersionNumber__',
    },
    disableConfirmationModalDelay: false,
    showNgxsLogs: false,
};
