import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AllowIn, ShortcutInput } from '../../components/ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts.interfaces';
import { KeyboardShortcutsService } from '../../components/ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts.service';
import { GlobalShortcutKeys } from '../../enumerations/global.shortcutKeys';

@Injectable({ providedIn: 'root' })
export class ShortcutKeysService {
    private addedShortcutIds: string[][] = [];
    private addedShortcutGroups: ShortcutInput[][] = [];
    private globalShortcutKeys: ShortcutInput[] = [
        this.registerShortcut(GlobalShortcutKeys.Help, () => {}, 'Global shortcuts', 'Toggle help window'),
        this.registerShortcut(GlobalShortcutKeys.StartTour, () => {}, 'Global shortcuts', 'Start tour guide'),
    ];

    public changeDetectorRef: ChangeDetectorRef;
    public keyboardShortcutsService: KeyboardShortcutsService;

    public registerShortcuts(shortcuts: ShortcutInput[]) {
        this.cleanAllShortcuts();
        this.registerShortcutBinding(shortcuts);

        this.changeDetectorRef.detectChanges();
    }

    public unregisterShortcuts(shortcutGroupName: string) {
        this.cleanAllShortcuts();
        var shortcutGroupToRemove = this.findRegisteredShortcutGroupByShortcutGroupName(shortcutGroupName);

        const index = this.addedShortcutGroups.indexOf(shortcutGroupToRemove, 0);
        const isShortcutGroupAdded = index > -1;

        if (isShortcutGroupAdded) {
            this.addedShortcutGroups.splice(index, 1);
        }

        this.addedShortcutIds.push(this.keyboardShortcutsService.add(this.addGlobalAndLatestShortcutKeys()));
        this.changeDetectorRef.markForCheck();
    }

    public registerShortcutHelp(keyboardShortcutsService: KeyboardShortcutsService, changeDetectorRef: ChangeDetectorRef) {
        this.keyboardShortcutsService = keyboardShortcutsService;

        this.addedShortcutGroups.push(this.globalShortcutKeys);
        this.addedShortcutIds.push([...this.keyboardShortcutsService.add(this.globalShortcutKeys)]);

        this.changeDetectorRef = changeDetectorRef;
    }

    public registerShortcut(shortcut: string, method: () => void, label?: string, description?: string, target?: HTMLElement) {
        const allowIn = [AllowIn.Input, AllowIn.Textarea, AllowIn.Select];
        return {
            key: shortcut,
            preventDefault: true,
            allowIn: allowIn,
            target: target,
            command: method,
            label: label,
            description: description,
        } as ShortcutInput;
    }

    private registerShortcutBinding(shortcuts: ShortcutInput[]) {
        var shortcutGroupByKey = this.findRegisteredShortcutGroupByKey(shortcuts);
        const index = this.addedShortcutGroups.indexOf(shortcutGroupByKey, 0);
        const isShortcutKeyAlreadyAssigned = index > -1;

        if (isShortcutKeyAlreadyAssigned) {
            var shortcutGroupByLabel = this.findRegisteredShortcutGroupByLabel(shortcuts);

            if (shortcutGroupByLabel == null) {
                this.addedShortcutGroups.push(shortcuts);
            }
        } else {
            this.addedShortcutGroups.push(shortcuts);
        }

        this.addedShortcutIds.push([...this.keyboardShortcutsService.add(this.addGlobalAndLatestShortcutKeys())]);
    }

    private cleanAllShortcuts() {
        this.keyboardShortcutsService.remove([].concat(...this.addedShortcutIds));
        this.addedShortcutIds = [];
    }

    private findRegisteredShortcutGroupByKey(shortcuts: ShortcutInput[]) {
        return this.addedShortcutGroups.find((addedShortcutGroup) => addedShortcutGroup.find((shortcutOfGroup) => shortcutOfGroup.key === shortcuts[0]?.key));
    }

    private findRegisteredShortcutGroupByLabel(shortcuts: ShortcutInput[]) {
        return this.findRegisteredShortcutGroupByShortcutGroupName(shortcuts[0]?.label);
    }

    private findRegisteredShortcutGroupByShortcutGroupName(shortcutGroupName: string) {
        return this.addedShortcutGroups.find((addedShortcut) => addedShortcut.find((x) => x.label === shortcutGroupName));
    }

    private addGlobalAndLatestShortcutKeys() {
        const globalShortcutGroup = this.addedShortcutGroups[0];
        const latestShortcutGroup = this.addedShortcutGroups[this.addedShortcutGroups.length - 1];

        if (this.addedShortcutGroups.length === 1) {
            return [...globalShortcutGroup];
        }
        return [...globalShortcutGroup, ...latestShortcutGroup];
    }
}
