import { SearchResult } from '@api';

export interface IApplicationSearchStateModel {
    tenantSearchedDeviceAssets: SearchResult;
}

export const applicationSearchStateModelDefault = {
    name: 'search',
    defaults: {
        tenantSearchedDeviceAssets: {},
    },
};
