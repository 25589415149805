import { GlobalShortcutKeys } from '../../enumerations/global.shortcutKeys';
import { ConfirmationButton, IConfirmationButton } from './confirmation-button';

export const getDefaultConfirmationButtons = () =>
    [
        new ConfirmationButton('btn-yes', 'Yes', true, ['btn', 'btn-primary'], GlobalShortcutKeys.SaveAndClose),
        new ConfirmationButton('btn-cancel', 'Cancel', false, ['btn', 'btn-secondary'], GlobalShortcutKeys.Close),
    ] as IConfirmationButton[];

export const getCancelButton = () => new ConfirmationButton('btn-cancel', 'Cancel', 'Cancel', ['btn', 'btn-secondary'], GlobalShortcutKeys.Close) as IConfirmationButton;

export const getUnsavedChangesConfirmationButtons = () =>
    [
        new ConfirmationButton('btn-save-changes', 'Save changes', 'SaveChanges', ['btn', 'btn-primary'], GlobalShortcutKeys.SaveAndClose),
        new ConfirmationButton('btn-discard-changes', 'Discard changes', 'DiscardChanges', ['btn', 'btn-secondary']),
        new ConfirmationButton('btn-cancel', 'Cancel', 'Cancel', ['btn', 'btn-secondary'], GlobalShortcutKeys.Close),
    ] as IConfirmationButton[];
