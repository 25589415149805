import { Selector } from '@ngxs/store';
import { NotificationsState } from './notifications.state';
import { INotificationsStateModel } from './notifications.state.model';

export class NotificationsSelectors {
    @Selector([NotificationsState])
    public static notifications(state: INotificationsStateModel) {
        return state.notifications;
    }
}
