import { Injectable } from '@angular/core';
import { ApplicationInsights, ITelemetryItem, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

export enum LogLevel {
    trace,
    info,
    warning,
    error,
}

@Injectable({ providedIn: 'root' })
export class LoggingService {
    private appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.instrumentationKey,
                disableCorrelationHeaders: false,
                disableExceptionTracking: false,
                disableAjaxTracking: false,
                disableTelemetry: false,
                enableCorsCorrelation: true,
                appId: 'Portal',
            },
        });
        this.appInsights.loadAppInsights();
        this.registerAppInsightsLogsProcessor();
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public trace(message: string, ...optionalParams: any[]) {
        if (this.isLogLevelActive(LogLevel.trace)) {
            console.debug(message, optionalParams);
        }
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public info(message: string, ...optionalParams: any[]) {
        if (this.isLogLevelActive(LogLevel.info)) {
            console.info(message, optionalParams);
        }
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    public warn(message: string, ...optionalParams: any[]) {
        if (this.isLogLevelActive(LogLevel.warning)) {
            console.warn(message, optionalParams);
            this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Warning });
        }
    }

    public error(message: string, error?: Error): void;
    public error(message: string, optionalParams?: { [name: string]: string }): void;
    public error(message: string, error?: Error | { [name: string]: string }): void {
        if (typeof message === 'object') {
            message = JSON.stringify(message);
        }

        if (this.isLogLevelActive(LogLevel.error)) {
            console.error(message, error);

            if (error != null && error.message != null) {
                this.appInsights.trackException({ exception: new Error(error.message) });
            } else {
                this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Error });
            }
        }
    }

    public isLogLevelActive(logLevel: LogLevel) {
        const currentLogLevel = localStorage.getItem('logLevel') ?? environment.logLevel;
        // @ts-ignore
        return LogLevel[currentLogLevel] <= logLevel;
    }

    private registerAppInsightsLogsProcessor() {
        this.appInsights.addTelemetryInitializer((telemetry: ITelemetryItem) => {
            const baseType = telemetry?.baseType;
            if (baseType === 'ExceptionData') {
                const errorMessage = telemetry?.data?.message ?? '';

                // Ignore ResizeObserver error to logged in application insight
                if (errorMessage.includes('ResizeObserver')) {
                    return false;
                }
            }

            return true;
        });
    }
}
