@if (options.closeButton) {
    <button (click)="remove()" class="toast-close-button" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
}
@if (title) {
    <div [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
        @if (duplicatesCount) {
            [{{ duplicatesCount + 1 }}]
        }
    </div>
}
@if (message && options.enableHtml) {
    <div role="alertdialog" aria-live="polite" [class]="options.messageClass" [innerHTML]="message"></div>
}
@if (message && !options.enableHtml) {
    <div role="alertdialog" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
        {{ message }}
    </div>
}

<div class="mt-3">
    @for (btn of buttons; track trackByFn($index, btn)) {
        <a class="btn btn-sm me-1" (click)="action(btn)">
            {{ btn.title }}
        </a>
    }
</div>
@if (options.progressBar) {
    <div>
        <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
}
