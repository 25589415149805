import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SearchResult } from '@api/model/models';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GetTenantSearchedDeviceAssets, ResetTenantSearch } from '../../state/application-search-state/application-search.actions';
import { ApplicationSearchState } from '../../state/application-search-state/application-search.state';
import { ApplicationState } from '../../state/application.state';
import { IDeviceSearch } from './models/DeviceSearch.interface';
import { ISearchInput } from './models/SearchInput.interface';

@Component({
    selector: 'app-search',
    templateUrl: './application-search.component.html',
    styleUrls: ['./application-search.component.scss'],
})
export class ApplicationSearchComponent implements OnInit, OnDestroy {
    @Select(ApplicationSearchState.getApplicationSearchResult) public searchResult$: Observable<SearchResult[]>;
    public searchCtrl = new UntypedFormControl();
    public typeaheadSubject: Subject<string> = new Subject<string>();

    private typeaheadSubscription$: Subscription;
    @ViewChild('appSearch') private appSearch: NgSelectComponent;

    constructor(private readonly store: Store) {}

    @HostListener('window:keydown', ['$event'])
    public handleKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey && event.key.toLowerCase() === 'f') {
            event.preventDefault();
            this.appSearch.focus();
        }
    }

    public ngOnInit(): void {
        const tenantName = this.store.selectSnapshot(ApplicationState.tenantName);
        this.typeaheadSubscription$ = this.typeaheadSubject.pipe(debounceTime(20)).subscribe((search) => {
            this.store.dispatch(new GetTenantSearchedDeviceAssets(search, tenantName));
        });
    }

    public ngOnDestroy(): void {
        this.typeaheadSubscription$.unsubscribe();
    }

    public openDeviceAssets(item: IDeviceSearch) {
        if (item.Type === 'EdgeDevice') {
            this.store.dispatch(new Navigate(['/devices', item.id]));
        }
        if (item.Type === 'Asset') {
            this.store.dispatch(new Navigate(['/devices', item.edgeDevice.id, 'assets', item.id]));
        }
    }

    public closeSearch() {
        this.store.dispatch(new ResetTenantSearch());
        this.searchCtrl.reset();
    }

    public checkInput(inputSearch: ISearchInput) {
        if (inputSearch.term.length < 3) {
            this.closeSearch();
        }
    }
}
