import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalShortcutKeys } from 'src/app/shared/enumerations/global.shortcutKeys';
import { IConfirmationButton } from '../../confirmation-modal/confirmation-button';
import { getUnsavedChangesConfirmationButtons } from '../../confirmation-modal/confirmation-buttons-repository';
import { ConfirmationModalService } from '../../confirmation-modal/confirmation-modal.service';
import { ActiveToolboxComponent } from './activeToolboxComponent';
import { StandardToolboxMode } from './standard-toolbox-mode';
import { StandardToolboxRef, ToolboxData } from './standard-toolbox-ref';
import { StandardToolboxComponent } from './standard-toolbox.component';

@Injectable({
    providedIn: 'root',
})
export class StandardToolboxService {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    private result: Promise<any>;
    private resolve: (result?: object) => void;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    private activeComponentRef: Map<string, ComponentRef<any>>;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        this.activeComponentRef = new Map<string, ComponentRef<any>>();
    }

    public open<T>(
        toolboxId: string,
        currentComponent: ViewContainerRef,
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        toolbox: any,
        title: string,
        args?: T,
        displayCloseButton: boolean = true,
        mode: StandardToolboxMode = StandardToolboxMode.Over
    ): ActiveToolboxComponent<T> {
        this.init();

        // Insert sidebar to the current component
        currentComponent.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(StandardToolboxComponent);

        //Add toolbox to sidebar
        const toolboxComponent = this.componentFactoryResolver.resolveComponentFactory(toolbox);
        const toolboxRef = new StandardToolboxRef();
        const standardToolboxRefInjector = Injector.create({
            providers: [
                { provide: StandardToolboxRef, useValue: toolboxRef },
                { provide: ToolboxData, useValue: args },
            ],
        });
        const componentReference = currentComponent.createComponent(componentFactory, undefined, standardToolboxRefInjector);

        componentReference.instance.title = title;
        componentReference.instance.displayCloseButton = displayCloseButton;
        componentReference.instance.mode = mode;

        const activeComponent = componentReference.instance.toolboxContainer.createComponent(toolboxComponent, undefined, standardToolboxRefInjector);
        this.activeComponentRef.set(toolboxId, activeComponent);

        this.commandButtonsClick(toolboxId, toolboxRef, componentReference);

        componentReference.changeDetectorRef.detectChanges();

        return new ActiveToolboxComponent<T>(activeComponent, this.result);
    }

    private commandButtonsClick(toolboxId: string, toolboxRef: StandardToolboxRef, componentReference: ComponentRef<StandardToolboxComponent>) {
        const subscription = new Subscription();
        subscription.add(
            toolboxRef.closeToolbox.pipe(take(1)).subscribe((entity) => {
                const activeComponent = this.activeComponentRef.get(toolboxId);
                activeComponent?.destroy();
                this.activeComponentRef.delete(toolboxId);
                componentReference.destroy();

                if (entity) {
                    this.resolve(entity as object);
                }

                subscription.unsubscribe();
            })
        );
    }

    public hasChanges(toolboxId: string) {
        const activeComponent = this.activeComponentRef.get(toolboxId);
        return activeComponent?.instance?.hasChanges() === true;
    }

    public async openUnsavedChangesDialog<T>(title: string, toolboxId: string, confirmationModalService: ConfirmationModalService, standardToolbox: ActiveToolboxComponent<T>) {
        const hasChanges = this.hasChanges(toolboxId);

        if (hasChanges) {
            const areChangesValid = standardToolbox.componentRef?.form?.valid === true;

            const confirmationMessage = areChangesValid
                ? `You have modified the ${title}. You can save your changes, discard your changes or cancel to continue editing.`
                : 'Your changes are currently not valid. Fix the changes to be able to save.';

            const confirmationButtons = getUnsavedChangesConfirmationButtons() as Array<IConfirmationButton>;

            confirmationButtons[0].ngClass = { btn: true, 'btn-primary': true, 'd-none': !areChangesValid };
            confirmationButtons[1].ngClass = {
                btn: true,
                'btn-secondary': areChangesValid,
                'btn-primary': !areChangesValid,
            };
            confirmationButtons[1].shortcutKey = areChangesValid ? null : GlobalShortcutKeys.SaveAndClose;

            try {
                const result = await confirmationModalService.open('Unsaved Changes', confirmationMessage, confirmationButtons);

                if (result === 'SaveChanges') {
                    standardToolbox.componentRef.save();
                    return true;
                } else if (result === 'DiscardChanges') {
                    standardToolbox.componentRef.cancel();
                    return true;
                } else if (result === 'Cancel' || result === false) {
                    return false;
                }
            } catch {
                return false;
            }
        }
        return true;
    }

    private init() {
        this.result = new Promise((resolve) => {
            this.resolve = resolve;
        });
        this.result.then(null, () => {});
    }
}
