/**
 * achtBytes IoT API
 * The achtBytes IoT API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@achtbytes.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AlertActionType = 0;

export const AlertActionType = {
    NUMBER_0: 0 as AlertActionType
};

