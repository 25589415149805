import { NgClass } from '@angular/common';
import { Observable, defer, map, take, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalShortcutKeys } from '../../enumerations/global.shortcutKeys';

export interface IConfirmationButton {
    id: string;
    title: string;
    result: string | boolean;
    disabled: boolean;
    shortcutKey?: GlobalShortcutKeys;
    ngClass: NgClass | string[] | { [key: string]: boolean };
    remainingSeconds: number;
    remainingSeconds$: Observable<number>;
    click(): void;
    setDelay(delay: number): void;
}

export class ConfirmationButton implements IConfirmationButton {
    private delay: number;

    public remainingSeconds$ = defer(() =>
        timer(0, 1000).pipe(
            take(this.delay + 1),
            map((num) => this.delay - num)
        )
    );

    public remainingSeconds = 0;

    constructor(
        public id: string,
        public title: string,
        public result: string | boolean,
        public ngClass: NgClass | string[] | { [key: string]: boolean },
        public shortcutKey?: GlobalShortcutKeys,
        public disabled = false
    ) {}

    public click(): void {}

    public setDelay(delay: number) {
        if (environment.disableConfirmationModalDelay) {
            // this return safes you a lot of life time
            // use it wisely
            return;
        }

        this.delay = delay;
    }
}
