import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Login, Logout } from 'src/app/shared/state/application.actions';
import { ApplicationState } from 'src/app/shared/state/application.state';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
    @Select(ApplicationState.isUserLoggedIn) public isUserLoggedIn$: Observable<boolean>;
    @Select(ApplicationState.currentUserName) public username$: Observable<string>;

    constructor(private readonly store: Store) {}

    public login() {
        this.store.dispatch(new Login());
    }

    public logout() {
        this.store.dispatch(new Logout());
    }
}
