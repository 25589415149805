export const referenceTypes = [
    { id: 'Asset', name: 'Asset' },
    { id: 'TenantWorkflowModule', name: 'Workflow Module' },
    { id: 'AlertAction', name: 'Alert Action' },
    { id: 'AlertCondition', name: 'Alert Condition' },
    { id: 'TenantDashboardWidget', name: 'Dashboard Widget' },
    { id: 'DeviceWorkflow', name: 'IO-Link Workflow' },
    { id: 'DeviceAnalytics', name: 'IO-Link Analytics' },
];

export const referenceWarnings = [
    { id: 'DeviceIsOffline', name: 'Device is offline, workflow will remain on device after deletion.' },
    { id: 'Unauthorized', name: 'You are not authorized to delete this entity, please contact your administrator.' },
];
