export class GetTenantSearchedDeviceAssets {
    public static readonly type = '[Application] Tenant Search';

    constructor(
        public search: string = '',
        public tenantName: string = ''
    ) {}
}

export class ResetTenantSearch {
    public static readonly type = '[Application] Reset Tenant Search';
}
