import { Injectable } from '@angular/core';
import { TenantService } from '@api/api/tenant.service';
import { SearchResult } from '@api/model/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import * as actions from './application-search.actions';
import { IApplicationSearchStateModel, applicationSearchStateModelDefault } from './application-search.state.model';

@State<IApplicationSearchStateModel>(applicationSearchStateModelDefault)
@Injectable()
export class ApplicationSearchState {
    constructor(private tenantService: TenantService) {}

    @Selector()
    public static getApplicationSearchResult(state: IApplicationSearchStateModel) {
        const result = [
            ...state.tenantSearchedDeviceAssets.edgeDeviceItems?.map((p) => ({ ...p, Type: 'EdgeDevice', ShowName: p.name })),
            ...state.tenantSearchedDeviceAssets.assetItems?.map((p) => ({
                ...p,
                Type: 'Asset',
                ShowName: p.name + ' (' + p.edgeDevice.name + ')',
            })),
        ] as SearchResult[];

        return result.filter((r) => !!r);
    }

    @Action(actions.GetTenantSearchedDeviceAssets)
    public getTenantSearchedDeviceAssets({ patchState }: StateContext<IApplicationSearchStateModel>, action: actions.GetTenantSearchedDeviceAssets) {
        if (action.search == null || action.search.length <= 2) {
            return;
        }

        return this.tenantService.getTenantSearchedDeviceAssets(action.tenantName, action.search).pipe(
            tap((tenantSearchedDeviceAssets) => {
                patchState({ tenantSearchedDeviceAssets });
            })
        );
    }

    @Action(actions.ResetTenantSearch)
    public resetTenantSearch({ patchState }: StateContext<IApplicationSearchStateModel>) {
        patchState({ tenantSearchedDeviceAssets: {} });
    }
}
