<div class="d-flex flex-row align-items-center">
    <div class="d-none d-md-block">{{ paginationDetails }}</div>
    <div class="ms-auto">
        <ngb-pagination
            class="me-auto"
            [page]="data?.pages?.currentPage"
            (pageChange)="emitPageChange($event)"
            [pageSize]="data?.pages?.size"
            [collectionSize]="data?.pages?.totalItems"
            [boundaryLinks]="true"
            size="sm"
            [rotate]="true"
            [maxSize]="3"
        >
        </ngb-pagination>
    </div>
</div>
