import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApplicationState } from '../../state/application.state';

@Injectable({ providedIn: 'root' })
export class FeaturesService {
    private features: Set<string>;

    constructor(private store: Store) {
        // permission service is created once
        // eslint-disable-next-line rxjs/no-ignored-subscription
        this.store.select(ApplicationState.features).subscribe((features) => {
            this.features = new Set<string>(features);
        });
    }

    public hasFeatures(features: string[]) {
        for (const feature of features) {
            if (!this.features.has(feature.toLowerCase())) {
                return false;
            }
        }

        return true;
    }
}
