@switch (status) {
    @case (0) {
        <div>
            <span class="offline"></span>
        </div>
    }
    @case (1) {
        <div>
            <span class="online"></span>
        </div>
    }
    @case (2) {
        <div>
            <span class="partial-online" ngbTooltip="Some assets are offline" placement="right"></span>
        </div>
    }
}
