import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable  @typescript-eslint/no-explicit-any */
@Directive({
    selector: '[ngLet]',
})
export class LetDirective {
    @Input() public set ngLet(context: any) {
        this.context.$implicit = this.context.ngLet = context;
        this.updateView();
    }

    public context: any = {};

    constructor(
        private vcRef: ViewContainerRef,
        private templateRef: TemplateRef<any>
    ) {}

    public updateView() {
        this.vcRef.clear();
        this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
}
