import { Inject, Injectable } from '@angular/core';
import { TenantService, UpdateTenantUserCommand, UserTenantInformation } from '@api';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TENANT_ID } from 'src/app/authorization/authorization-factories';
import { ApplicationSearchState } from './application-search-state/application-search.state';
import { AcceptDisclaimer, CompleteTour, Login, Logout, SetTenantUser, ToggleSideBar, UserLoggedIn, UserLoggedOut } from './application.actions';
import { NotificationsState } from './notifications-state/notifications.state';

export class ApplicationStateModel {
    public tenantName: string;
    public currentAadUser: AccountInfo;
    public isUserLoggedIn: boolean;
    public sidebarIsCollapsed: boolean;
    public userTenantInformation: UserTenantInformation;
}

@State<ApplicationStateModel>({
    name: 'application',
    children: [ApplicationSearchState, NotificationsState],
    defaults: {
        currentAadUser: null,
        tenantName: null,
        isUserLoggedIn: false,
        sidebarIsCollapsed: false,
        userTenantInformation: null,
    },
})
@Injectable({ providedIn: 'root' })
export class ApplicationState implements NgxsOnInit {
    @Selector()
    public static isUserLoggedIn(state: ApplicationStateModel) {
        return state.isUserLoggedIn;
    }

    @Selector()
    public static currentUserName(state: ApplicationStateModel) {
        return decodeURIComponent(state.currentAadUser.name);
    }

    @Selector()
    public static tenantName(state: ApplicationStateModel) {
        return decodeURIComponent(state.tenantName);
    }

    @Selector()
    public static currentUser(state: ApplicationStateModel) {
        return state.currentAadUser;
    }

    @Selector()
    public static sidebarIsCollapsed(state: ApplicationStateModel) {
        return state.sidebarIsCollapsed;
    }

    @Selector()
    public static userTenant(state: ApplicationStateModel) {
        return state.userTenantInformation;
    }

    @Selector()
    public static permissions(state: ApplicationStateModel) {
        return state.userTenantInformation?.permissions as Permissions;
    }

    @Selector()
    public static features(state: ApplicationStateModel) {
        return state.userTenantInformation?.features;
    }

    @Selector()
    public static hasAcceptedDisclaimer(state: ApplicationStateModel) {
        return state.userTenantInformation?.user.hasAcceptedSecureEnvironmentDisclaimer ?? false;
    }

    constructor(
        private authService: MsalService,
        @Inject(TENANT_ID) private tenantId: string,
        private tenantService: TenantService
    ) {}

    public ngxsOnInit({ dispatch }: StateContext<ApplicationStateModel>) {
        const aadUser = this.authService.instance.getActiveAccount();
        if (aadUser) {
            dispatch(new UserLoggedIn(aadUser));
        } else {
            dispatch(new UserLoggedOut());
        }
    }

    @Action(Login)
    public login({ dispatch }: StateContext<ApplicationStateModel>) {
        dispatch(new Navigate(['./login']));
    }

    @Action(Logout)
    public logout() {
        const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()?.[0];
        this.authService.logoutRedirect({ account: activeAccount });
    }

    @Action(UserLoggedIn)
    public userLoggedIn({ patchState }: StateContext<ApplicationStateModel>, action: UserLoggedIn) {
        if (!action.user) {
            return;
        }

        patchState({
            currentAadUser: action.user,
            isUserLoggedIn: true,
            tenantName: this.tenantId,
        });
    }

    @Action(UserLoggedOut)
    public userLoggedOut({ setState }: StateContext<ApplicationStateModel>) {
        setState(null);
    }

    @Action(ToggleSideBar)
    public toggleSideBar({ patchState, getState }: StateContext<ApplicationStateModel>) {
        const state = getState();
        patchState({ sidebarIsCollapsed: !state.sidebarIsCollapsed });
    }

    @Action(SetTenantUser)
    public setTenantCustomization(ctx: StateContext<ApplicationStateModel>, action: SetTenantUser) {
        const tenant = action.userTenantInformation.tenant;
        if ((tenant.name?.trim() ?? '') === '') {
            tenant.name = 'achtBytes IoT';
        }

        ctx.patchState({ userTenantInformation: action.userTenantInformation });
    }

    @Action(AcceptDisclaimer)
    public acceptDisclaimer({ getState }: StateContext<ApplicationStateModel>, action: AcceptDisclaimer) {
        const tenantName = getState().tenantName;
        return this.tenantService.updateTenantUser(tenantName, {
            hasAcceptedSecureEnvironmentDisclaimer: true,
        } as UpdateTenantUserCommand);
    }

    @Action(CompleteTour)
    public completeTour({ getState, setState }: StateContext<ApplicationStateModel>, action: CompleteTour) {
        const state = getState();

        // if the user already completed the tour, do not update again
        if (state.userTenantInformation.user.hasCompletedTour) {
            return of();
        }

        return this.tenantService
            .updateTenantUser(state.tenantName, {
                hasCompletedTour: true,
            } as UpdateTenantUserCommand)
            .pipe(
                tap(() => {
                    setState(
                        patch({
                            userTenantInformation: patch({ user: patch({ hasCompletedTour: true }) }),
                        })
                    );
                })
            );
    }
}
