import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbDatepickerModule, NgbModalModule, NgbNavModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { ApplicationSearchComponent } from './components/application-search/application-search.component';
import { AssetListComponent } from './components/asset-list/asset-list.component';
import { CommandExecutionListComponent } from './components/command-execution-list/command-execution-list.component';
import { CommandExecutionResultComponent } from './components/command-execution-result/command-execution-result.component';
import { CommandsPropertiesComponent } from './components/commands-properties/commands-properties.component';
import { CommandsComponent } from './components/commands/commands.component';
import { ComponentBaseService } from './components/component.base.service';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalService } from './components/confirmation-modal/confirmation-modal.service';
import { CytomapComponent } from './components/cytomap/cytomap.component';
import { DeviceStatusComponent } from './components/device-status/device-status.component';
import { EdgeDeviceListComponent } from './components/edge-device-list/edge-device-list.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { EventSeverityComponent } from './components/event-severity/event-severity.component';
import { HasFeatureDirective } from './components/features/features.directive';
import { GroupListComponent } from './components/group-list/group-list.component';
import { LastKnownTelemetryValuesComponent } from './components/last-known-telemetry-values/last-known-telemetry-values.component';
import { LiveDataActivationComponent } from './components/live-data-activation/live-data-activation.component';
import { KeyboardShortcutsModuleStego } from './components/ng-keyboard-shortcuts-stego/ng-keyboard-shortcuts.module';
import { NotificationsFilterComponent } from './components/notifications/notifications-filter/notifications-filter.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OverviewSortingComponent } from './components/overviews/overview-sorting/overview-sorting.component';
import { DisabledPermissionDirective } from './components/permissions/disabled-permission.directive';
import { HasPermissionDirective } from './components/permissions/permission.directive';
import { PortalHeaderComponent } from './components/portal-header/portal-header.component';
import { PropertiesOverviewComponent } from './components/properties-overview/properties-overview.component';
import { PropertiesTreeViewComponent } from './components/properties-overview/properties-tree-view/properties-tree-view.component';
import { RoleMenuSelectComponent } from './components/properties-overview/role-menu-select/role-menu-select.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { PropertyInformationComponent } from './components/properties/property-information/property-information.component';
import { StandardComponentsModule } from './components/standard-components/standard-components.module';
import { StandardToolboxService } from './components/standard-components/standard-toolbox/standard-toolbox.service';
import { TimeRangeFormComponent } from './components/time-range-form/time-range-form.component';
import { GroupUserListComponent } from './components/user-list/user-list.component';
import { DebounceInputDirective } from './directives/debounce-input/debounce-input.directive';
import { FileDragDirective } from './directives/file-drag.directive';
import { LetDirective } from './directives/ng-let.directive';
import { NgbDropdownOnHoverDirective } from './directives/ngb-dropdown-on-hover.directive';
import { BeautifyJsonPipe } from './pipes/beautify-json.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { ObjectToNumeratedArrayPipe } from './pipes/objectToNumeratedArrayPipe.pipe';
import { ReplacePipe } from './pipes/replace/replace.pipe';
import { SplitCasingPipe } from './pipes/split-casing.pipe';
import { BreadcrumbsService } from './services/breadcrumbs/breadcrumbs.service';
import { DeactivationGuard } from './services/deactivation-guard/deactivation-guard.service';
import { FeaturesService } from './services/features/features.service';
import { HelpGuideService } from './services/help-guide.service';
import { CustomToastComponent } from './services/notifications-service/custom-toastr/custom-toast.component';
import { NotificationsService } from './services/notifications-service/notifications.service';
import { PermissionsService } from './services/permissions/permissions.service';
import { PushNotificationsService } from './services/push-notifications.service';
import { ShortcutKeysService } from './services/shortcutKeys/shortcutKeys.service';

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: 'var(--nav_item_active)',
    fgsColor: 'var(--active_accent_color)',
    fgsSize: 40,
    bgsSize: 40,
    blur: 3,
    pbColor: 'var(--nav_item_active)',
    delay: 200,
    maxTime: 30000,
    minTime: 300,
    pbThickness: 5,
};

@NgModule({
    declarations: [
        ConfirmationModalComponent,
        EdgeDeviceListComponent,
        AssetListComponent,
        DeviceStatusComponent,
        EventListComponent,
        EventSeverityComponent,
        CytomapComponent,
        ApplicationSearchComponent,
        PropertiesComponent,
        LastKnownTelemetryValuesComponent,
        CommandExecutionListComponent,
        CommandExecutionResultComponent,
        HasPermissionDirective,
        DisabledPermissionDirective,
        HasFeatureDirective,
        RoleMenuSelectComponent,
        PropertiesOverviewComponent,
        PropertiesTreeViewComponent,
        CommandsComponent,
        CommandsPropertiesComponent,
        GroupListComponent,
        GroupUserListComponent,
        PropertyInformationComponent,
        PortalHeaderComponent,
        NgbDropdownOnHoverDirective,
        LetDirective,
        ErrorListComponent,
        ObjectToNumeratedArrayPipe,
        BeautifyJsonPipe,
        SplitCasingPipe,
        ReplacePipe,
        NotificationsComponent,
        NotificationsFilterComponent,
        CustomToastComponent,
        OverviewSortingComponent,
        LiveDataActivationComponent,
        FileDragDirective,
        DebounceInputDirective,
        TimeRangeFormComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbAccordionModule,
        NgbNavModule,
        NgSelectModule,
        NgbModalModule,
        NgbTooltipModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        StandardComponentsModule,
        KeyboardShortcutsModuleStego.forRoot(),
        ClipboardModule,
        LocalDatePipe,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbNavModule,
        NgbModalModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        NgbAccordionModule,
        NgbTooltipModule,
        NgxUiLoaderModule,
        ConfirmationModalComponent,
        EdgeDeviceListComponent,
        AssetListComponent,
        CytomapComponent,
        EventListComponent,
        PropertiesComponent,
        LastKnownTelemetryValuesComponent,
        DeviceStatusComponent,
        CommandExecutionListComponent,
        ApplicationSearchComponent,
        HasPermissionDirective,
        DisabledPermissionDirective,
        StandardComponentsModule,
        HasFeatureDirective,
        RoleMenuSelectComponent,
        PropertiesOverviewComponent,
        PropertiesTreeViewComponent,
        CommandsComponent,
        CommandsPropertiesComponent,
        GroupListComponent,
        GroupUserListComponent,
        KeyboardShortcutsModuleStego,
        PortalHeaderComponent,
        NgbDropdownOnHoverDirective,
        LetDirective,
        ErrorListComponent,
        ObjectToNumeratedArrayPipe,
        BeautifyJsonPipe,
        SplitCasingPipe,
        ReplacePipe,
        ClipboardModule,
        NotificationsComponent,
        NotificationsFilterComponent,
        OverviewSortingComponent,
        LiveDataActivationComponent,
        FileDragDirective,
        DebounceInputDirective,
        TimeRangeFormComponent,
        LocalDatePipe,
    ],
    providers: [DecimalPipe, DeactivationGuard, NotificationsService, ComponentBaseService],
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [ConfirmationModalService, BreadcrumbsService, PermissionsService, FeaturesService, PushNotificationsService, StandardToolboxService, NotificationsService, ShortcutKeysService, HelpGuideService],
        };
    }
}
