import { UserTenantInformation } from '@api';
import { AccountInfo } from '@azure/msal-browser';

export class Login {
    public static readonly type = '[Application] Login';
}

export class Logout {
    public static readonly type = '[Application] Logout';
}
export class UserLoggedIn {
    public static readonly type = '[Application] User logged in';
    constructor(public user: AccountInfo) {}
}

export class UserLoggedOut {
    public static readonly type = '[Application] User logged out';
}

export class ToggleSideBar {
    public static readonly type = '[Application] Toggle Sidebar';
}

export class ResetToggleSideBar {
    public static readonly type = '[Application] Toggle Sidebar Reset';
}

export class SetTenantUser {
    public static readonly type = '[Application] Set Tenant User';
    constructor(public userTenantInformation: UserTenantInformation) {}
}

export class AcceptDisclaimer {
    public static readonly type = '[Application] Accept disclaimer';
}

export class CompleteTour {
    public static readonly type = '[Application] Complete tour';
}
