@if (errors?.length) {
    <div class="error-list">
        <ng-template #errorList>
            @for (error of errors; track error) {
                <ul class="element-error-list">
                    <li>
                        {{ error }}
                    </li>
                </ul>
            }
        </ng-template>
        <i class="fas fa-exclamation-circle" tooltipClass="standard-tooltip" placement="auto" [ngbTooltip]="errorList"></i>
    </div>
}
