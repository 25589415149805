import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../../services/permissions/permissions.service';

@Directive({ selector: '[appHasPermission]' })
export class HasPermissionDirective implements OnInit {
    @Input('appHasPermission') public hasPermission: string;

    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<unknown>,
        private permissionsService: PermissionsService
    ) {}

    public ngOnInit() {
        const permissions = this.hasPermission.split(',').map((p) => p.trim());
        const hasPermissions = this.permissionsService != null && this.permissionsService.hasPermissions(permissions);
        if (hasPermissions) {
            const result = this.viewContainer.createEmbeddedView(this.templateRef);
            result.detectChanges();
        }
    }
}
