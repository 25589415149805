import { AfterViewInit, ChangeDetectorRef, Component, ViewChildren } from '@angular/core';
import { Tenant } from '@api';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResetToggleSideBar, ToggleSideBar } from '../../../shared/state/application.actions';
import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { ApplicationState } from '../../state/application.state';
import { INavigationItemDefinition, NavSideBarItems } from './nav-side-bar.repository';

@Component({
    selector: 'app-nav-side-bar',
    templateUrl: './nav-side-bar.component.html',
    styleUrls: ['./nav-side-bar.component.scss'],
})
export class NavSideBarComponent implements AfterViewInit {
    private get isMobileMode() {
        return window.matchMedia('only screen and (max-width: 430px)').matches;
    }

    public opened: boolean;
    public version = environment.version;
    @Select(ApplicationState.sidebarIsCollapsed) public isCollapsed$: Observable<boolean>;
    @Select(ApplicationState.userTenant) public tenant$: Observable<Tenant>;
    @ViewChildren(NgbDropdown) public dropdowns: NgbDropdown[];

    public navSideBarItems: INavigationItemDefinition[] = [];

    constructor(
        private readonly store: Store,
        public breadcrumbs: BreadcrumbsService,
        private permissionService: PermissionsService,
        private cdRef: ChangeDetectorRef
    ) {}

    public ngAfterViewInit(): void {
        this.navSideBarItems = NavSideBarItems;
        this.cdRef.detectChanges();
    }

    public toggleSideBar() {
        this.store.dispatch(new ToggleSideBar());
    }

    public toggled(opened: boolean) {
        this.opened = opened;
    }

    public toggleExpanded(navItem: INavigationItemDefinition, openState: boolean = null) {
        if (this.isMobileMode) {
            this.store.dispatch(new ResetToggleSideBar());

            this.toggleSideBar();
            this.closeAllItemsSubMenus();
        }

        this.closeAllSubMenus(navItem);
        if (openState) {
            navItem.IsExpanded = openState;
        } else {
            navItem.IsExpanded = !navItem.IsExpanded;
        }
    }

    public closeAllItemsSubMenus(navItem: INavigationItemDefinition = null) {
        for (let navigationItem of this.navSideBarItems) {
            navigationItem.IsExpanded = false;
        }
    }

    public closeAllSubMenus(navItem: INavigationItemDefinition = null) {
        for (let navigationItem of this.navSideBarItems) {
            if (navigationItem.IsExpanded && navigationItem !== navItem) {
                navigationItem.IsExpanded = false;
            }
        }
    }

    public closeDropdown() {
        for (let dropdown of this.dropdowns) {
            dropdown.close();
        }
    }

    public showExpand(IsExpanded: boolean) {
        if (!this.opened) {
            return false;
        } else if (!IsExpanded) {
            return false;
        } else {
            return true;
        }
    }

    public checkPermissionTree(navItem: INavigationItemDefinition) {
        let permissions = navItem.subItems.map((item) => item.permission);

        if (permissions.length === 0) {
            return this.hasAnyPermission([navItem.permission]);
        }

        // if we have subitems and an empty parent permission, ignore parent
        if (navItem.permission != '') {
            permissions.push(navItem.permission);
        }

        return this.hasAnyPermission(permissions);
    }

    private hasAnyPermission(permissions: string[]): boolean {
        for (const permission of permissions) {
            if (permission == '') {
                return true;
            }

            if (this.permissionService.hasPermissions([permission])) {
                return true;
            }
        }

        return false;
    }

    public openSubItem(subItem: string) {
        if (this.isMobileMode) {
            this.toggleSideBar();
        }
        this.store.dispatch(new Navigate([subItem]));
    }
}
