import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeviewModule } from '@laijuthomas/ngx-treeview';
import { NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule, NgbPopoverModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SidebarModule } from 'ngx-angular-sidebar';
import { ColorPickerModule } from 'ngx-color-picker';
import { LocalDatePipe } from '../../pipes/local-date.pipe';
import { StandardColorpickerComponent } from './standard-colorpicker/standard-colorpicker.component';
import { StandardContainerComponent } from './standard-container/standard-container.component';
import { StandardDataTableComponent } from './standard-data-table/standard-data-table.component';
import { StandardDatetimePickerComponent } from './standard-datetime-picker/standard-datetime-picker.component';
import { StandardDropdownResizableMenuComponent } from './standard-dropdown-resizable-menu/standard-dropdown-resizable-menu.component';
import { StandardPaginationComponent } from './standard-pagination/standard-pagination.component';
import { StandardRangeDatePickerComponent } from './standard-range-date-picker/standard-range-date-picker.component';
import { StandardSearchBoxComponent } from './standard-search-box/standard-search-box.component';
import { StandardSliderComponent } from './standard-slider/standard-slider.component';
import { StandardTextboxComponent } from './standard-textbox/standard-textbox.component';
import { StandardTimespanComponent } from './standard-timespan/standard-timespan.component';
import { StandardToggleSwitchTriStateComponent } from './standard-toggle-switch-tristate/standard-toggle-switch-tristate.component';
import { StandardToggleSwitchComponent } from './standard-toggle-switch/standard-toggle-switch.component';
import { StandardToolbarComponent } from './standard-toolbar/standard-toolbar.component';
import { StandardToolboxComponent } from './standard-toolbox/standard-toolbox.component';
import { StandardTreeviewComponent } from './standard-treeview/standard-treeview.component';

@NgModule({
    declarations: [
        StandardContainerComponent,
        StandardPaginationComponent,
        StandardTextboxComponent,
        StandardColorpickerComponent,
        StandardToggleSwitchComponent,
        StandardToggleSwitchTriStateComponent,
        StandardSliderComponent,
        StandardToolboxComponent,
        StandardToolbarComponent,
        StandardDatetimePickerComponent,
        StandardDataTableComponent,
        StandardSearchBoxComponent,
        StandardRangeDatePickerComponent,
        StandardTimespanComponent,
        StandardTreeviewComponent,
        StandardDropdownResizableMenuComponent,
    ],
    imports: [
        ReactiveFormsModule,
        NgbDropdownModule,
        CommonModule,
        FormsModule,
        ColorPickerModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        NgbPopoverModule,
        NgbPaginationModule,
        NgbTooltipModule,
        SidebarModule.forRoot(),
        NgxDatatableModule,
        TreeviewModule.forRoot(),
        LocalDatePipe,
    ],
    exports: [
        StandardContainerComponent,
        StandardPaginationComponent,
        StandardTextboxComponent,
        StandardColorpickerComponent,
        StandardToggleSwitchComponent,
        StandardToggleSwitchTriStateComponent,
        StandardSliderComponent,
        StandardToolboxComponent,
        StandardToolbarComponent,
        StandardDataTableComponent,
        StandardSearchBoxComponent,
        NgbTooltipModule,
        StandardDatetimePickerComponent,
        NgxDatatableModule,
        StandardRangeDatePickerComponent,
        StandardTimespanComponent,
        StandardDropdownResizableMenuComponent,
        StandardTreeviewComponent,
    ],
})
export class StandardComponentsModule {}
