import { InjectionToken } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig, protectedResources } from './authorization-config';

export const TENANT_ID = new InjectionToken<string>('TENANT_ID');

export function queryTenantId(): string {
    const urlTenant = location.pathname.match(/\/([^\/]+)/)?.[1] ?? '';
    if (urlTenant === 'not-found' || urlTenant === 'disclaimer') {
        return null;
    }
    return urlTenant;
}

export function baseUrlFactory() {
    const tenantId = queryTenantId();
    if (tenantId == null) {
        return '/';
    }
    return `/${tenantId}`;
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

    protectedResourceMap.set(protectedResources.connectPortalAPI.endpoint, [
        {
            httpMethod: 'GET',
            scopes: [...protectedResources.connectPortalAPI.scopes.read],
        },
        {
            httpMethod: 'POST',
            scopes: [...protectedResources.connectPortalAPI.scopes.write],
        },
        {
            httpMethod: 'PUT',
            scopes: [...protectedResources.connectPortalAPI.scopes.write],
        },
        {
            httpMethod: 'DELETE',
            scopes: [...protectedResources.connectPortalAPI.scopes.write],
        },
    ]);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest,
    };
}
