/**
 * achtBytes IoT API
 * The achtBytes IoT API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@achtbytes.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AggregatedStateTelemetryItemsResult } from '../model/models';
import { AggregatedTelemetryItemsResult } from '../model/models';
import { CapabilityModelDefinition } from '../model/models';
import { CompletionItem } from '../model/models';
import { CreateAnalyticsCommand } from '../model/models';
import { DeviceAnalytics } from '../model/models';
import { DeviceAnalyticsItemsResult } from '../model/models';
import { LastKnownTelemetry } from '../model/models';
import { ProblemDetails } from '../model/models';
import { Query } from '../model/models';
import { Query2 } from '../model/models';
import { Query3 } from '../model/models';
import { SignatureHelp } from '../model/models';
import { TelemetryAggregationFunction } from '../model/models';
import { UpdateAnalyticsCommand } from '../model/models';
import { UpdateAnalyticsMetadataCommand } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

    protected basePath = 'http://localhost:5001';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param deviceId 
     * @param tenant 
     * @param createAnalyticsCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAnalytics(deviceId: string, tenant: string, createAnalyticsCommand: CreateAnalyticsCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DeviceAnalytics>;
    public createAnalytics(deviceId: string, tenant: string, createAnalyticsCommand: CreateAnalyticsCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DeviceAnalytics>>;
    public createAnalytics(deviceId: string, tenant: string, createAnalyticsCommand: CreateAnalyticsCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DeviceAnalytics>>;
    public createAnalytics(deviceId: string, tenant: string, createAnalyticsCommand: CreateAnalyticsCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling createAnalytics.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling createAnalytics.');
        }
        if (createAnalyticsCommand === null || createAnalyticsCommand === undefined) {
            throw new Error('Required parameter createAnalyticsCommand was null or undefined when calling createAnalytics.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DeviceAnalytics>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics`,
            createAnalyticsCommand,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param force 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAnalytics(deviceId: string, analyticsId: string, tenant: string, force?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public deleteAnalytics(deviceId: string, analyticsId: string, tenant: string, force?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public deleteAnalytics(deviceId: string, analyticsId: string, tenant: string, force?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public deleteAnalytics(deviceId: string, analyticsId: string, tenant: string, force?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling deleteAnalytics.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling deleteAnalytics.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling deleteAnalytics.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (force !== undefined && force !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>force, 'force');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param tenant 
     * @param orderBy 
     * @param orderByDirection 
     * @param filter 
     * @param page 
     * @param pageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnalytics(deviceId: string, tenant: string, orderBy?: string, orderByDirection?: string, filter?: string, page?: number, pageSize?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DeviceAnalyticsItemsResult>;
    public getAnalytics(deviceId: string, tenant: string, orderBy?: string, orderByDirection?: string, filter?: string, page?: number, pageSize?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DeviceAnalyticsItemsResult>>;
    public getAnalytics(deviceId: string, tenant: string, orderBy?: string, orderByDirection?: string, filter?: string, page?: number, pageSize?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DeviceAnalyticsItemsResult>>;
    public getAnalytics(deviceId: string, tenant: string, orderBy?: string, orderByDirection?: string, filter?: string, page?: number, pageSize?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getAnalytics.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getAnalytics.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (orderBy !== undefined && orderBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderBy, 'orderBy');
        }
        if (orderByDirection !== undefined && orderByDirection !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderByDirection, 'orderByDirection');
        }
        if (filter !== undefined && filter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>filter, 'filter');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>pageSize, 'pageSize');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DeviceAnalyticsItemsResult>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnalyticsById(deviceId: string, analyticsId: string, tenant: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DeviceAnalytics>;
    public getAnalyticsById(deviceId: string, analyticsId: string, tenant: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DeviceAnalytics>>;
    public getAnalyticsById(deviceId: string, analyticsId: string, tenant: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DeviceAnalytics>>;
    public getAnalyticsById(deviceId: string, analyticsId: string, tenant: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getAnalyticsById.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling getAnalyticsById.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getAnalyticsById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DeviceAnalytics>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param telemetryIds 
     * @param fromDate 
     * @param toDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnalyticsStates(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AggregatedStateTelemetryItemsResult>;
    public getAnalyticsStates(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AggregatedStateTelemetryItemsResult>>;
    public getAnalyticsStates(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AggregatedStateTelemetryItemsResult>>;
    public getAnalyticsStates(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getAnalyticsStates.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling getAnalyticsStates.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getAnalyticsStates.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (telemetryIds) {
            telemetryIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'telemetryIds');
            })
        }
        if (fromDate !== undefined && fromDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromDate, 'fromDate');
        }
        if (toDate !== undefined && toDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDate, 'toDate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AggregatedStateTelemetryItemsResult>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/states`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param telemetryIds 
     * @param zeroBasedTelemetryIds 
     * @param fromDate 
     * @param toDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnalyticsTelemetry(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, zeroBasedTelemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AggregatedTelemetryItemsResult>;
    public getAnalyticsTelemetry(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, zeroBasedTelemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AggregatedTelemetryItemsResult>>;
    public getAnalyticsTelemetry(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, zeroBasedTelemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AggregatedTelemetryItemsResult>>;
    public getAnalyticsTelemetry(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, zeroBasedTelemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getAnalyticsTelemetry.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling getAnalyticsTelemetry.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getAnalyticsTelemetry.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (telemetryIds) {
            telemetryIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'telemetryIds');
            })
        }
        if (zeroBasedTelemetryIds) {
            zeroBasedTelemetryIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'zeroBasedTelemetryIds');
            })
        }
        if (fromDate !== undefined && fromDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromDate, 'fromDate');
        }
        if (toDate !== undefined && toDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDate, 'toDate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AggregatedTelemetryItemsResult>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/telemetry`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param telemetryIds 
     * @param zeroBasedTelemetryIds 
     * @param fromDate 
     * @param toDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnalyticsTimelineTelemetry(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, zeroBasedTelemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AggregatedTelemetryItemsResult>;
    public getAnalyticsTimelineTelemetry(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, zeroBasedTelemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AggregatedTelemetryItemsResult>>;
    public getAnalyticsTimelineTelemetry(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, zeroBasedTelemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AggregatedTelemetryItemsResult>>;
    public getAnalyticsTimelineTelemetry(deviceId: string, analyticsId: string, tenant: string, telemetryIds?: Array<string>, zeroBasedTelemetryIds?: Array<string>, fromDate?: string, toDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getAnalyticsTimelineTelemetry.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling getAnalyticsTimelineTelemetry.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getAnalyticsTimelineTelemetry.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (telemetryIds) {
            telemetryIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'telemetryIds');
            })
        }
        if (zeroBasedTelemetryIds) {
            zeroBasedTelemetryIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'zeroBasedTelemetryIds');
            })
        }
        if (fromDate !== undefined && fromDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromDate, 'fromDate');
        }
        if (toDate !== undefined && toDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDate, 'toDate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<AggregatedTelemetryItemsResult>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/telemetry/timeline`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCapabilityModel(deviceId: string, analyticsId: string, tenant: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CapabilityModelDefinition>;
    public getCapabilityModel(deviceId: string, analyticsId: string, tenant: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CapabilityModelDefinition>>;
    public getCapabilityModel(deviceId: string, analyticsId: string, tenant: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CapabilityModelDefinition>>;
    public getCapabilityModel(deviceId: string, analyticsId: string, tenant: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getCapabilityModel.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling getCapabilityModel.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getCapabilityModel.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CapabilityModelDefinition>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/capabilityModel`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param deviceId 
     * @param query 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompletions(tenant: string, deviceId: string, query: Query, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CompletionItem>>;
    public getCompletions(tenant: string, deviceId: string, query: Query, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CompletionItem>>>;
    public getCompletions(tenant: string, deviceId: string, query: Query, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CompletionItem>>>;
    public getCompletions(tenant: string, deviceId: string, query: Query, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getCompletions.');
        }
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getCompletions.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getCompletions.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<CompletionItem>>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/completions`,
            query,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param telemetryName 
     * @param fromDate 
     * @param toDate 
     * @param aggregation 
     * @param shouldExcludeNullValuesWhileAggregating 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLastKnownTelemetryValue(deviceId: string, analyticsId: string, tenant: string, telemetryName?: string, fromDate?: string, toDate?: string, aggregation?: TelemetryAggregationFunction, shouldExcludeNullValuesWhileAggregating?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<LastKnownTelemetry>>;
    public getLastKnownTelemetryValue(deviceId: string, analyticsId: string, tenant: string, telemetryName?: string, fromDate?: string, toDate?: string, aggregation?: TelemetryAggregationFunction, shouldExcludeNullValuesWhileAggregating?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<LastKnownTelemetry>>>;
    public getLastKnownTelemetryValue(deviceId: string, analyticsId: string, tenant: string, telemetryName?: string, fromDate?: string, toDate?: string, aggregation?: TelemetryAggregationFunction, shouldExcludeNullValuesWhileAggregating?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<LastKnownTelemetry>>>;
    public getLastKnownTelemetryValue(deviceId: string, analyticsId: string, tenant: string, telemetryName?: string, fromDate?: string, toDate?: string, aggregation?: TelemetryAggregationFunction, shouldExcludeNullValuesWhileAggregating?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getLastKnownTelemetryValue.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling getLastKnownTelemetryValue.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getLastKnownTelemetryValue.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (telemetryName !== undefined && telemetryName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>telemetryName, 'telemetryName');
        }
        if (fromDate !== undefined && fromDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromDate, 'fromDate');
        }
        if (toDate !== undefined && toDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDate, 'toDate');
        }
        if (aggregation !== undefined && aggregation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>aggregation, 'aggregation');
        }
        if (shouldExcludeNullValuesWhileAggregating !== undefined && shouldExcludeNullValuesWhileAggregating !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>shouldExcludeNullValuesWhileAggregating, 'shouldExcludeNullValuesWhileAggregating');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<LastKnownTelemetry>>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/telemetry/lastValue`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param deviceId 
     * @param query2 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuickInfo(tenant: string, deviceId: string, query2: Query2, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public getQuickInfo(tenant: string, deviceId: string, query2: Query2, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public getQuickInfo(tenant: string, deviceId: string, query2: Query2, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public getQuickInfo(tenant: string, deviceId: string, query2: Query2, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getQuickInfo.');
        }
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getQuickInfo.');
        }
        if (query2 === null || query2 === undefined) {
            throw new Error('Required parameter query2 was null or undefined when calling getQuickInfo.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/quickinfo`,
            query2,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param tenant 
     * @param deviceId 
     * @param query3 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSignatures(tenant: string, deviceId: string, query3: Query3, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SignatureHelp>;
    public getSignatures(tenant: string, deviceId: string, query3: Query3, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SignatureHelp>>;
    public getSignatures(tenant: string, deviceId: string, query3: Query3, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SignatureHelp>>;
    public getSignatures(tenant: string, deviceId: string, query3: Query3, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling getSignatures.');
        }
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getSignatures.');
        }
        if (query3 === null || query3 === undefined) {
            throw new Error('Required parameter query3 was null or undefined when calling getSignatures.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SignatureHelp>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/signatures`,
            query3,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pauseAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public pauseAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public pauseAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public pauseAnalytics(deviceId: string, analyticsId: string, tenant: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling pauseAnalytics.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling pauseAnalytics.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling pauseAnalytics.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/pause`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DeviceAnalytics>;
    public publishAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DeviceAnalytics>>;
    public publishAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DeviceAnalytics>>;
    public publishAnalytics(deviceId: string, analyticsId: string, tenant: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling publishAnalytics.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling publishAnalytics.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling publishAnalytics.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<DeviceAnalytics>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/publish`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resumeAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public resumeAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public resumeAnalytics(deviceId: string, analyticsId: string, tenant: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public resumeAnalytics(deviceId: string, analyticsId: string, tenant: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling resumeAnalytics.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling resumeAnalytics.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling resumeAnalytics.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/resume`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param updateAnalyticsCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAnalytics(deviceId: string, analyticsId: string, tenant: string, updateAnalyticsCommand: UpdateAnalyticsCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DeviceAnalytics>;
    public updateAnalytics(deviceId: string, analyticsId: string, tenant: string, updateAnalyticsCommand: UpdateAnalyticsCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DeviceAnalytics>>;
    public updateAnalytics(deviceId: string, analyticsId: string, tenant: string, updateAnalyticsCommand: UpdateAnalyticsCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DeviceAnalytics>>;
    public updateAnalytics(deviceId: string, analyticsId: string, tenant: string, updateAnalyticsCommand: UpdateAnalyticsCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling updateAnalytics.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling updateAnalytics.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling updateAnalytics.');
        }
        if (updateAnalyticsCommand === null || updateAnalyticsCommand === undefined) {
            throw new Error('Required parameter updateAnalyticsCommand was null or undefined when calling updateAnalytics.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<DeviceAnalytics>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}`,
            updateAnalyticsCommand,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param inputId 
     * @param tenant 
     * @param inputValue 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAnalyticsInput(deviceId: string, analyticsId: string, inputId: string, tenant: string, inputValue?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateAnalyticsInput(deviceId: string, analyticsId: string, inputId: string, tenant: string, inputValue?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateAnalyticsInput(deviceId: string, analyticsId: string, inputId: string, tenant: string, inputValue?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateAnalyticsInput(deviceId: string, analyticsId: string, inputId: string, tenant: string, inputValue?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling updateAnalyticsInput.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling updateAnalyticsInput.');
        }
        if (inputId === null || inputId === undefined) {
            throw new Error('Required parameter inputId was null or undefined when calling updateAnalyticsInput.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling updateAnalyticsInput.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (inputValue !== undefined && inputValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>inputValue, 'inputValue');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/input/${encodeURIComponent(String(inputId))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param deviceId 
     * @param analyticsId 
     * @param tenant 
     * @param updateAnalyticsMetadataCommand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAnalyticsMetadata(deviceId: string, analyticsId: string, tenant: string, updateAnalyticsMetadataCommand: UpdateAnalyticsMetadataCommand, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DeviceAnalytics>;
    public updateAnalyticsMetadata(deviceId: string, analyticsId: string, tenant: string, updateAnalyticsMetadataCommand: UpdateAnalyticsMetadataCommand, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DeviceAnalytics>>;
    public updateAnalyticsMetadata(deviceId: string, analyticsId: string, tenant: string, updateAnalyticsMetadataCommand: UpdateAnalyticsMetadataCommand, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DeviceAnalytics>>;
    public updateAnalyticsMetadata(deviceId: string, analyticsId: string, tenant: string, updateAnalyticsMetadataCommand: UpdateAnalyticsMetadataCommand, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling updateAnalyticsMetadata.');
        }
        if (analyticsId === null || analyticsId === undefined) {
            throw new Error('Required parameter analyticsId was null or undefined when calling updateAnalyticsMetadata.');
        }
        if (tenant === null || tenant === undefined) {
            throw new Error('Required parameter tenant was null or undefined when calling updateAnalyticsMetadata.');
        }
        if (updateAnalyticsMetadataCommand === null || updateAnalyticsMetadataCommand === undefined) {
            throw new Error('Required parameter updateAnalyticsMetadataCommand was null or undefined when calling updateAnalyticsMetadata.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<DeviceAnalytics>(`${this.configuration.basePath}/${encodeURIComponent(String(tenant))}/api/devices/${encodeURIComponent(String(deviceId))}/analytics/${encodeURIComponent(String(analyticsId))}/metaData`,
            updateAnalyticsMetadataCommand,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
