// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function tryParseJSON(input: string, defaultValue: object = null): any {
    if (!input) {
        return defaultValue;
    }

    try {
        return JSON.parse(input);
    } catch {
        return defaultValue;
    }
}

export function isValidJSON(input?: string) {
    if (!input) {
        return false;
    }

    try {
        JSON.parse(input);
        return true;
    } catch {
        return false;
    }
}

export function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function getRandomId() {
    return Math.random().toString(36).substring(2);
}
