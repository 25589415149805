/* eslint-disable rxjs/no-nested-subscribe */
/* eslint-disable rxjs/no-ignored-subscription */
// No need unsubscribe, because singleton service will be unsubscribed automatically after closing page.
import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { LoggingService } from './shared/services/logging.service';
import { IToastButton } from './shared/services/notifications-service/custom-toastr/toast-button';
import { NotificationsService } from './shared/services/notifications-service/notifications.service';

@Injectable()
export class ServiceWorkerUpdateService {
    private readonly isNewVersionAvailableName = 'isNewVersionAvailable';
    private promptUser: boolean;

    private get isNewVersionAvailable() {
        return localStorage.getItem(this.isNewVersionAvailableName) === 'true';
    }

    private set isNewVersionAvailable(value: boolean) {
        localStorage.setItem(this.isNewVersionAvailableName, String(value));
    }

    constructor(
        private serviceWorkerUpdate: SwUpdate,
        private notificationsService: NotificationsService,
        private logging: LoggingService
    ) {
        if (!this.serviceWorkerUpdate.isEnabled) {
            this.logging.trace('Update service is not enabled!');
        }

        this.fetchUpdateWhenAppIsStable();
        this.fetchUpdateEveryTwoHours();
    }

    private fetchUpdateWhenAppIsStable() {
        const versionReadyToUpdate = this.serviceWorkerUpdate.versionUpdates.pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY' && !this.promptUser && this.serviceWorkerUpdate.isEnabled));
        versionReadyToUpdate.subscribe(() => {
            this.activateUpdate();
        });
    }

    private fetchUpdateEveryTwoHours() {
        const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
        const everyTwoHoursTimer$ = interval(twoHoursInMilliseconds).pipe(tap(() => (this.promptUser = true)));
        everyTwoHoursTimer$.subscribe(async () => {
            if (!this.promptUser || !this.serviceWorkerUpdate.isEnabled) {
                return;
            }

            try {
                const updateFound = await this.serviceWorkerUpdate.checkForUpdate();
                if (updateFound) {
                    this.isNewVersionAvailable = true;
                }

                if (this.isNewVersionAvailable) {
                    const refreshButtonId = 'btn-refresh';
                    const refreshToastMessageHtml = '<div class="mt-1">A new version of <b>achtBytes IoT</b> is available. Refresh to get the newest version.</div>';
                    const refreshButton: IToastButton = { id: refreshButtonId, title: 'Refresh' };

                    this.notificationsService.showCustomToastr(refreshToastMessageHtml, 'New Version', [refreshButton]).onAction.subscribe((btn: IToastButton) => {
                        if (btn.id === refreshButtonId) {
                            this.activateUpdate();
                        }
                    });
                }
            } catch (error) {
                this.logging.error('Failed to check for service worker updates:', error);
            }
        });
    }

    private activateUpdate() {
        this.serviceWorkerUpdate.activateUpdate().then(() => {
            this.isNewVersionAvailable = false;
            document.location.reload();
        });
    }
}
