export const ROUTER_PERMISSIONS = {
    tenant: 'tenant.read',
    edgeDevice: 'edgeDevice.read',
    asset: 'asset.read',
    workflow: 'deviceWorkflow.read',
    workflowVariable: 'deviceWorkflowVariable.read',
    workflowModules: 'tenantWorkflowModule.read',
    dashboards: 'tenantdashboard.read',
    provisionEdgeDevice: 'edgeDevice.create',
    tenantCustomization: 'tenant.write',
    groupAdministration: 'group.write',
    userManagement: 'tenant.write',
    auditHistory: 'tenantAuditHistory.read',
    dataExport: 'dataExport.read',
    alert: 'alert.read',
    tenants: 'support.read',
    remoteMaintenance: 'support.read',
    analytics: 'deviceAnalytics.read',
    userInvite: 'tenantUserInvite.read',
};
