import { Component, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { TENANT_ID } from 'src/app/authorization/authorization-factories';
import { AcceptDisclaimer } from 'src/app/shared/state/application.actions';

@Component({
    selector: 'app-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnDestroy {
    public hasAccepted: boolean;
    private subscription = new Subscription();

    constructor(
        @Inject(TENANT_ID) public tenantId: string,
        private store: Store
    ) {
        this.hasAccepted = false;
    }

    public confirmAcceptedDisclaimer() {
        this.subscription.add(
            this.store.dispatch(new AcceptDisclaimer()).subscribe(() => {
                window.location.href = `/${this.tenantId}`;
            })
        );
    }

    public acceptDisclaimer() {
        this.hasAccepted = !this.hasAccepted;
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
