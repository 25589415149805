import { LayoutModule } from '@angular/cdk/layout';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApiModule } from '@api/api.module';
import { Configuration } from '@api/configuration';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { SidebarModule } from 'ngx-angular-sidebar';
import { ToastrModule } from 'ngx-toastr';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TelemetryChartPreferencesCustomStorage } from './asset-details/asset-telemetry-state/telemetry-chart-preferences-custom-storage';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory, TENANT_ID, baseUrlFactory, queryTenantId } from './authorization/authorization-factories';
import { AuthorizationService } from './authorization/authorization.service';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DashboardCustomStorage } from './dashboards/dashboard-custom-storage';
import { NavSideBarComponent } from './shared/components/nav-side-bar/nav-side-bar.component';
import { HttpErrorInterceptor } from './shared/services/http-error-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { ApplicationSearchState } from './shared/state/application-search-state/application-search.state';
import { ApplicationState } from './shared/state/application.state';
import { NotificationsState } from './shared/state/notifications-state/notifications.state';

const tenantId = queryTenantId();

@NgModule({
    declarations: [AppComponent, NavSideBarComponent, NotFoundComponent, DisclaimerComponent, NotAuthorizedComponent],
    imports: [
        MsalModule,
        BrowserAnimationsModule,
        CommonModule,
        BrowserModule,
        LayoutModule,
        BrowserAnimationsModule,
        SharedModule.forRoot(),
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        SidebarModule.forRoot(),
        ApiModule.forRoot(
            () =>
                new Configuration({
                    basePath: environment.basePath,
                })
        ),
        // ! NGXS must be initialised for root before other feature state modules.
        NgxsModule.forRoot([ApplicationState, ApplicationSearchState, NotificationsState], {
            developmentMode: !environment.production,
            compatibility: {
                strictContentSecurityPolicy: true, // needed for content security policy
            },
        }),
        NgxsStoragePluginModule.forRoot({
            key: [
                ApplicationState,
                'tenant.tenantMap.tenantMapState',
                {
                    key: 'assetDetails.assetTelemetries.telemetryChartPreferences',
                    engine: TelemetryChartPreferencesCustomStorage,
                },
                {
                    key: 'analyticsTelemetry.telemetryChartPreferences',
                    engine: TelemetryChartPreferencesCustomStorage,
                },
                'deviceDetails.deviceDetailsMapStatus.deviceDetailsMapStatusState',
                {
                    key: 'dashboards.lastSelectedDashboard',
                    engine: DashboardCustomStorage,
                },
            ],
        }),
        NgxsRouterPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot({ disabled: !environment.showNgxsLogs }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        HttpClientModule,
        // ! This needs to stay at the end of the list, in case other modules
        // ! are added that use their own routing.
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
    ],
    providers: [
        // HTTP interceptor is needed for MSAL to add authentication header while sending requests to backend
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: TENANT_ID,
            useValue: tenantId,
        },
        {
            provide: APP_BASE_HREF,
            useFactory: baseUrlFactory,
        },
        AuthorizationService,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        DashboardCustomStorage,
        TelemetryChartPreferencesCustomStorage,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
