import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdminRemoteMaintenanceService } from './api/adminRemoteMaintenance.service';
import { AlertService } from './api/alert.service';
import { AnalyticsService } from './api/analytics.service';
import { AssetGroupsService } from './api/assetGroups.service';
import { AssetsService } from './api/assets.service';
import { DashboardService } from './api/dashboard.service';
import { DataExportService } from './api/dataExport.service';
import { EdgeDevicesService } from './api/edgeDevices.service';
import { GlobalAdministrationService } from './api/globalAdministration.service';
import { NotificationsService } from './api/notifications.service';
import { TenantService } from './api/tenant.service';
import { UserService } from './api/user.service';
import { WorkflowService } from './api/workflow.service';
import { WorkflowModuleService } from './api/workflowModule.service';
import { WorkflowVariablesService } from './api/workflowVariables.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
