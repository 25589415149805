export const notificationTypes = {
    AssetStatusChanged: {
        title: 'Asset status changed',
        text: 'The asset status of asset {{ assetName }} on edge device {{ edgeDeviceName }} changed to {{ status }}.',
    },
    EdgeDeviceStatusChanged: {
        title: 'Edge device status changed',
        text: 'The edge device status of edge device {{ edgeDeviceName }} changed to {{ status }}.',
    },
    NewAsset: {
        title: 'New asset available',
        text: 'The asset {{ assetName }} is now available for edge device {{ edgeDeviceName }}.',
    },
    DeleteAsset: {
        title: 'Deleted asset',
        text: 'The asset {{ assetName }} was deleted from edge device {{ edgeDeviceName }}.',
    },
    DataExportCompleted: {
        title: 'Data export completed',
        text: 'The data export {{ name }} was successfully exported and is ready to be downloaded.',
    },
    DataExportFailed: {
        title: 'Data export failed',
        text: 'The data export {{ name }} failed to export. Try and export it again.',
    },
    IOLinkError: {
        title: 'Parameter IO Error',
        text: 'There was an parameter error on {{assetName}}: {{error}}',
    },
};

export const notificationSeverity = [
    { id: 0, name: 'Information' },
    { id: 1, name: 'Warning' },
    { id: 2, name: 'Error' },
];

export const notificationType = [
    { id: 'AssetStatusChanged', name: 'Asset status changed' },
    { id: 'EdgeDeviceStatusChanged', name: 'Edge device status changed' },
    { id: 'NewAsset', name: 'New asset' },
    { id: 'DeleteAsset', name: 'Deleted asset' },
    { id: 'DataExportCompleted', name: 'Data export completed' },
    { id: 'DataExportFailed', name: 'Data export failed' },
    { id: 'IOLinkError', name: 'Parameter IO Error' },
];
