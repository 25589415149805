@if (shortcut.description) {
    <div class="item d-flex align-items-center" [class.item--odd]="index % 2 !== 0">
        <div class="description">
            <span>{{ shortcut.description }}</span>
        </div>
        <div class="keys">
            @for (sKey of parsedKeys; track sKey; let i = $index) {
                <div class="key__container">
                    @for (key of sKey; track key; let keyIndex = $index) {
                        <div class="key">{{ key }}</div>
                        @if ((keyIndex + 1) % 2 !== 0 && sKey.length > 1) {
                            <div class="keySeparator">+</div>
                        }
                    }
                    @if (parsedKeys.length > 1 && i < parsedKeys.length - 1) {
                        <span class="separator"> / </span>
                    }
                </div>
            }
        </div>
    </div>
}
