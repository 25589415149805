import { ROUTER_PERMISSIONS } from 'src/app/authorization/router-permissions';

export interface INavigationItemDefinition {
    id: string;
    displayName: string;
    tooltip: string;
    routerLink: string;
    routerLinkOptions: {
        exact: boolean;
    };
    icon: string;
    permission: string;
    IsExpanded: boolean;
    subItems: INavigationItemDefinition[];
}

export const NavSideBarItems: INavigationItemDefinition[] = [
    {
        id: 'home',
        displayName: 'Home',
        tooltip: 'Home',
        routerLink: '/tenant',
        routerLinkOptions: { exact: true },
        icon: 'fa-home',
        permission: ROUTER_PERMISSIONS.tenant,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'dashboards',
        displayName: 'Dashboards',
        tooltip: 'Dashboards',
        routerLink: '/dashboards/',
        routerLinkOptions: { exact: false },
        icon: 'fa-th',
        permission: ROUTER_PERMISSIONS.dashboards,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'edge-devices',
        displayName: 'Edge Devices',
        tooltip: 'Edge Devices',
        routerLink: '/devices',
        routerLinkOptions: { exact: false },
        icon: 'fa-microchip',
        permission: ROUTER_PERMISSIONS.edgeDevice,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'assets-overview',
        displayName: 'IO-Link Assets',
        tooltip: 'IO-Link Assets',
        routerLink: '/assets/',
        routerLinkOptions: { exact: true },
        icon: 'fa-plug',
        permission: ROUTER_PERMISSIONS.asset,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'asset-groups-overview',
        displayName: 'Asset Groups',
        tooltip: 'Asset Groups',
        routerLink: '/asset-groups/',
        routerLinkOptions: { exact: false },
        icon: 'fa-object-group',
        permission: ROUTER_PERMISSIONS.asset,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'workflows',
        displayName: 'Workflows',
        tooltip: 'Workflows',
        routerLink: null,
        routerLinkOptions: { exact: true },
        icon: 'fa-project-diagram',
        permission: '',
        IsExpanded: false,
        subItems: [
            {
                id: 'overview',
                displayName: 'IO-Link Workflows',
                tooltip: 'IO-Link Workflows',
                routerLink: '/workflows',
                routerLinkOptions: { exact: true },
                icon: 'fa-project-diagram',
                permission: ROUTER_PERMISSIONS.workflow,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'modules',
                displayName: 'Workflow Modules',
                tooltip: 'Workflow Modules',
                routerLink: '/workflows/modules',
                routerLinkOptions: { exact: false },
                icon: 'fa-puzzle-piece',
                permission: ROUTER_PERMISSIONS.workflowModules,
                IsExpanded: false,
                subItems: [],
            },
        ],
    },
    {
        id: 'admin',
        displayName: 'Administration',
        tooltip: 'Administration',
        routerLink: null,
        routerLinkOptions: { exact: false },
        icon: 'fa-tasks',
        permission: '',
        IsExpanded: false,
        subItems: [
            {
                id: 'provision-edge-device',
                displayName: 'Provision Edge Device',
                tooltip: 'Provision Edge Device',
                routerLink: '/device/register',
                routerLinkOptions: {
                    exact: true,
                },
                icon: 'fa-server',
                permission: ROUTER_PERMISSIONS.provisionEdgeDevice,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'tenant-customization',
                displayName: 'Tenant Customization',
                tooltip: 'Change the apperance of stego-connect',
                routerLink: '/tenant/administration/customization',
                routerLinkOptions: {
                    exact: true,
                },
                icon: 'fa-cogs',
                permission: ROUTER_PERMISSIONS.tenantCustomization,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'group-administration',
                displayName: 'Groups Administration',
                tooltip: 'Manage your tenant permission groups',
                routerLink: '/tenant/administration/groups',
                routerLinkOptions: {
                    exact: false,
                },
                icon: 'fa-users',
                permission: ROUTER_PERMISSIONS.groupAdministration,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'user-management',
                displayName: 'User Management',
                tooltip: 'Manage your user',
                routerLink: '/tenant/administration/usermanagement',
                routerLinkOptions: {
                    exact: false,
                },
                icon: 'fa-user',
                permission: ROUTER_PERMISSIONS.userManagement,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'audit-history',
                displayName: 'Tenant Audit History',
                tooltip: 'Tenant Audit History',
                routerLink: '/tenant/administration/auditHistory',
                routerLinkOptions: {
                    exact: false,
                },
                icon: 'fa-history',
                permission: ROUTER_PERMISSIONS.auditHistory,
                IsExpanded: false,
                subItems: [],
            },
        ],
    },
    {
        id: 'data-export',
        displayName: 'Data Export',
        tooltip: 'Data Export',
        routerLink: '/export',
        routerLinkOptions: { exact: true },
        icon: 'fa-file-export',
        permission: ROUTER_PERMISSIONS.dataExport,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'alerts',
        displayName: 'Alerts',
        tooltip: 'Alerts',
        routerLink: '/alerts',
        routerLinkOptions: { exact: true },
        icon: 'fa-bell',
        permission: ROUTER_PERMISSIONS.alert,
        IsExpanded: false,
        subItems: [],
    },
    {
        id: 'global-admin',
        displayName: 'Global Administration',
        tooltip: 'Global Administration',
        routerLink: null,
        routerLinkOptions: { exact: true },
        icon: 'fa-user-cog',
        permission: '',
        IsExpanded: false,
        subItems: [
            {
                id: 'tenants',
                displayName: 'Tenants',
                tooltip: 'Tenants',
                routerLink: '/admin/tenants',
                routerLinkOptions: { exact: true },
                icon: 'fa-house-user',
                permission: ROUTER_PERMISSIONS.tenants,
                IsExpanded: false,
                subItems: [],
            },
            {
                id: 'remote-maintenance',
                displayName: 'Remote Maintenance',
                tooltip: 'Remote Maintenance',
                routerLink: '/admin/remoteMaintenance',
                routerLinkOptions: {
                    exact: false,
                },
                icon: 'fa-desktop',
                permission: ROUTER_PERMISSIONS.remoteMaintenance,
                IsExpanded: false,
                subItems: [],
            },
        ],
    },
];
