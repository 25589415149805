/* eslint-disable rxjs/no-ignored-subscription */
// We don't need unsubscribe observables in this service, because of using takeUntilDestroyed
import { HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TenantService, UserTenantInformation } from '@api';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, InteractionStatus, LogLevel, Logger, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { PromptValue } from '@azure/msal-common';
import { Store } from '@ngxs/store';
import { BehaviorSubject, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoggingService } from '../shared/services/logging.service';
import { PushNotificationsService } from '../shared/services/push-notifications.service';
import { SetTenantUser, UserLoggedIn } from '../shared/state/application.actions';
import { IdTokenClaimsWithPolicyId } from './Id-token-claims-with-policy-id';
import { TENANT_ID } from './authorization-factories';

@Injectable()
export class AuthorizationService {
    private destroyRef: DestroyRef = inject(DestroyRef);
    private tenantId: string = inject(TENANT_ID);
    private msalGuardConfig: MsalGuardConfiguration = inject(MSAL_GUARD_CONFIG) as unknown as MsalGuardConfiguration;
    private tenantService: TenantService = inject(TenantService);
    private store: Store = inject(Store);
    private authService: MsalService = inject(MsalService);
    private msalBroadcastService: MsalBroadcastService = inject(MsalBroadcastService);
    private logging: LoggingService = inject(LoggingService);
    private pushNotificationsService: PushNotificationsService = inject(PushNotificationsService);
    private router: Router = inject(Router);

    private isLoginInProgressSubject$ = new BehaviorSubject<boolean>(false);
    private hasPermissionsSubject$ = new BehaviorSubject<boolean>(false);
    private hasAcceptedSecureEnvironmentDisclaimerSubject$ = new BehaviorSubject<boolean>(false);

    public isLoginInProgress$ = this.isLoginInProgressSubject$.asObservable();
    public hasPermissions$ = this.hasPermissionsSubject$.asObservable();
    public hasAcceptedSecureEnvironmentDisclaimer$ = this.hasAcceptedSecureEnvironmentDisclaimerSubject$.asObservable();

    constructor() {
        this.setMSALLogger();
    }

    public subscribeLoginInProgress() {
        this.msalBroadcastService.inProgress$
            .pipe(
                tap((status: InteractionStatus) => {
                    if (status === InteractionStatus.None) {
                        this.checkAndSetActiveAccount();
                        return;
                    }

                    this.logging.trace('Logging is in progress, waiting for msal to take over.');
                    this.isLoginInProgressSubject$.next(true);
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe();
    }

    public handleRedirectAfterLogin(eventMessage: EventMessage) {
        const payload = eventMessage.payload as AuthenticationResult;
        const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (
            idtoken.acr?.toLowerCase() === environment.AzureActiveDirectory_B2C_AngularConfig.b2cPolicies.names.signUpSignIn.toLowerCase() ||
            idtoken.tfp?.toLowerCase() === environment.AzureActiveDirectory_B2C_AngularConfig.b2cPolicies.names.signUpSignIn.toLowerCase()
        ) {
            this.authService.instance.setActiveAccount(payload.account);
        }

        if (
            idtoken.acr?.toLowerCase() === environment.AzureActiveDirectory_B2C_AngularConfig.b2cPolicies.names.resetPassword.toLowerCase() ||
            idtoken.tfp?.toLowerCase() === environment.AzureActiveDirectory_B2C_AngularConfig.b2cPolicies.names.resetPassword.toLowerCase()
        ) {
            let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
                authority: environment.AzureActiveDirectory_B2C_AngularConfig.b2cPolicies.authorities.signUpSignIn.authority,
                scopes: [environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ConsentScopes.connectPortalAPI, environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ConsentScopes.openId],
                prompt: PromptValue.LOGIN,
            };

            this.login(signUpSignInFlowRequest);
        }
    }

    public handleRedirectPasswordReset(result: EventMessage) {
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
            let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
                authority: environment.AzureActiveDirectory_B2C_AngularConfig.b2cPolicies.authorities.resetPassword.authority,
                scopes: [],
            };

            this.login(resetPasswordFlowRequest);
        }
    }

    public handleAcquireToken() {
        const activeAccount = this.authService.instance.getActiveAccount() ?? this.authService.instance.getAllAccounts()?.[0];
        const sessionId = activeAccount?.idTokenClaims?.['sid'];

        setTimeout(() => {
            this.authService
                .acquireTokenRedirect({
                    sid: sessionId,
                    scopes: [environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ConsentScopes.connectPortalAPI, environment.AzureActiveDirectory_B2C_AngularConfig.AAD_ConsentScopes.openId],
                })
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe();
        });
    }

    private setMSALLogger() {
        this.authService.setLogger(
            new Logger({
                loggerCallback: (logLevel, message, _) => {
                    this.logMSAL(logLevel, message);
                },
                piiLoggingEnabled: false,
                logLevel: LogLevel.Verbose,
            })
        );
    }

    private logMSAL(logLevel: LogLevel, message: string) {
        if (logLevel === LogLevel.Verbose) {
            this.logging.trace(message);
        } else if (logLevel === LogLevel.Info) {
            this.logging.info(message);
        } else if (logLevel === LogLevel.Warning) {
            this.logging.warn(message);
        } else if (logLevel === LogLevel.Error) {
            this.logging.error(message);
        }
    }

    private checkAndSetActiveAccount() {
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }

        if (!activeAccount) {
            this.login(undefined);
            return;
        }

        this.store.dispatch(new UserLoggedIn(activeAccount));

        this.tenantService
            .getTenantUser(this.tenantId ?? '', 'response')
            .pipe(
                tap((tenantUserResult) => {
                    this.tenantPermissionsSucceeded(tenantUserResult.body);
                    this.startPushNotificationService();
                }),
                catchError((error) => {
                    this.tenantPermissionsFailed(error);
                    return throwError(() => error);
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe();

        this.isLoginInProgressSubject$.next(false);
    }

    private startPushNotificationService() {
        if (this.tenantId != null) {
            this.pushNotificationsService.start();
        }
    }

    private login(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect(userFlowRequest);
        }
    }

    private tenantPermissionsSucceeded(userTenantInformation: UserTenantInformation) {
        this.store.dispatch(new SetTenantUser(userTenantInformation));
        const hasAcceptedSecureEnvironmentDisclaimer = userTenantInformation.user.hasAcceptedSecureEnvironmentDisclaimer != null;

        this.hasPermissionsSubject$.next(true);
        this.hasAcceptedSecureEnvironmentDisclaimerSubject$.next(hasAcceptedSecureEnvironmentDisclaimer);

        if (!this.hasAcceptedSecureEnvironmentDisclaimerSubject$.getValue() && window.location.href.indexOf('disclaimer') === -1) {
            this.logging.trace('Redirecting user to disclaimer page');

            this.router.navigate(['disclaimer']);
        }
    }

    private tenantPermissionsFailed(request: HttpErrorResponse) {
        if (window.location.href.indexOf('not-found') >= 0) {
            // we are already on the not found page
            // do nothing so we don't get in a redirect loop
            return;
        }

        if (request.status === 302) {
            if (this.tenantId == null && request.error.redirectUrl.indexOf('not-found') >= 0) {
                // if we have no tenant we are probably already on the not found page
                // do nothing so we don't get in a redirect loop
                return;
            }

            // if we need to redirect on the not found page there might be an issue so log it as a warning
            const logMethod = window.location.href.indexOf('not-found') >= 0 ? this.logging.warn : this.logging.info;

            logMethod.bind(this.logging, `Redirecting to ${request.error.redirectUrl} because backend returned redirect response`, {
                tenantId: this.tenantId,
                redirectTo: request.error.redirectUrl,
                status: request.status.toString(),
            });

            window.location.href = request.error.redirectUrl;
        }
        if (request.status === 401 || request.status === 403 || request.status === 404) {
            if (this.tenantId == null) {
                // if we have no tenant we are probably already on the not found page
                // do nothing so we don't get in a redirect loop
                return;
            }

            this.logging.error(`The user was not authorized (${request.status}) for tenant ${this.tenantId}, redirecting to not found page`, { tenantId: this.tenantId, redirectTo: '/not-found', status: request.status.toString() });

            this.hasPermissionsSubject$.next(false);
            window.location.href = '/not-found';
        }
    }
}
