import { Injectable, inject } from '@angular/core';
import { TENANT_ID } from '../authorization/authorization-factories';
import { StorageEngineBase } from '../shared/classes/storage-engine-base';
import { tryParseJSON } from '../shared/extensions/string.extensions';
import { ITenantDashboard } from './interfaces/tenant-dashboard.interface';

@Injectable()
export class DashboardCustomStorage extends StorageEngineBase {
    private tenantId: string = inject(TENANT_ID);

    public getItem(key: string) {
        const dashboards = this.getSavedItems<ITenantDashboard>(key);
        const tenantDashboard = dashboards[this.tenantId];
        return JSON.stringify(tenantDashboard);
    }

    public setItem(key: string, value: unknown): void {
        const lastDashboard = tryParseJSON(value?.toString(), {}) as ITenantDashboard;
        if (Object.keys(lastDashboard).length === 0) {
            return;
        }

        const dashboards = this.getSavedItems<ITenantDashboard>(key);
        dashboards[this.tenantId] = lastDashboard;
        localStorage.setItem(key, JSON.stringify(dashboards));
    }
}
