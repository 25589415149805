import { ItemsResult } from '../interfaces/ItemsResult.model';

export function mergeItemsResultByTimestamp<T extends { id?: string; timestamp?: string }, TItemsResult extends ItemsResult<T>>(itemsResult: TItemsResult, newItems: T[]) {
    const filteredItems = itemsResult.items.map((oldItem) => {
        return {
            ...(newItems.find((e) => e.id === oldItem.id) ?? oldItem),
            timestamp: oldItem.timestamp,
        };
    });

    const filteredNewEvents = newItems.filter((n) => !filteredItems.find((e) => e.id === n.id));

    const mergedItemResult = {} as TItemsResult;
    mergedItemResult.items = [...filteredNewEvents, ...filteredItems].slice(0, itemsResult?.pages?.size ?? 10);
    mergedItemResult.pages = {
        currentPage: itemsResult?.pages?.currentPage ?? 1,
        size: itemsResult?.pages?.size ?? 10,
        totalItems: itemsResult?.pages?.totalItems + filteredNewEvents.length,
        totalPages: Math.ceil(Number((itemsResult?.pages?.totalItems + filteredNewEvents.length) / itemsResult?.pages?.size ?? 10)),
    };

    return mergedItemResult;
}

export function mergeItemsResult<T extends { id?: string }, TItemsResult extends ItemsResult<T>>(itemsResult: TItemsResult, newItems: T[], compareFn: (a: T, b: T) => number) {
    const itemsFiltered = itemsResult.items.filter((n) => !newItems.find((e) => e.id === n.id));

    const items = [...newItems, ...itemsFiltered].sort(compareFn).slice(0, itemsResult?.pages?.size ?? 10);
    const newItemsCount = newItems.length - (itemsResult.items.length - itemsFiltered.length);

    const mergedItemResult = {} as TItemsResult;
    mergedItemResult.items = items;
    mergedItemResult.pages = {
        currentPage: itemsResult?.pages?.currentPage ?? 1,
        size: itemsResult?.pages?.size ?? 10,
        totalItems: itemsResult?.pages?.totalItems + newItemsCount,
        totalPages: Math.ceil(Number((itemsResult?.pages?.totalItems + newItemsCount) / itemsResult?.pages?.size ?? 10)),
    };

    return mergedItemResult;
}

export function mergeItemsResultBy<T extends { id?: string }, TItemsResult extends ItemsResult<T>>(itemsResult: TItemsResult, newItems: T[], comparePropertySelectorFn: (a: T) => string) {
    return mergeItemsResult(itemsResult, newItems, (a, b) => {
        let x = comparePropertySelectorFn(a).toLowerCase();
        let y = comparePropertySelectorFn(b).toLowerCase();
        if (x < y) {
            return -1;
        }
        if (x > y) {
            return 1;
        }
        return 0;
    });
}
