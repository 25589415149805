/* eslint-disable  @typescript-eslint/no-explicit-any */
import { ComponentRef } from '@angular/core';

export class ActiveToolboxComponent<T> {
    private _result: Promise<T> | 'cancel';
    private _componentRef: any;

    public get result(): Promise<T> | 'cancel' {
        return this._result;
    }

    public get componentRef(): any {
        return this._componentRef;
    }

    constructor(toolbox: ComponentRef<any>, result: Promise<T>) {
        this._result = result;
        this._componentRef = toolbox.instance;
    }

    public dismiss(result?: T | 'cancel') {
        this._componentRef.standardToolboxRef.close(result);
    }
}
