<div class="not-found">
    <div class="header">
        <img src="assets/images/achtbytes-logo.png" height="35" class="mb-1 me-2" />
        <span class="header-title"></span>

        <ul class="menu">
            @if ((isUserLoggedIn$ | async) === false) {
                <li ngbTooltip="Login">
                    <a (click)="login()">
                        <li class="fas fa-sign-in-alt fa-lg"></li>
                        <span>Login</span>
                    </a>
                </li>
            }

            @if (isUserLoggedIn$ | async) {
                <li ngbTooltip="Logout">
                    <a (click)="logout()">
                        <li class="fas fa-sign-out-alt fa-lg"></li>
                        <span>{{ username$ | async }}</span>
                    </a>
                </li>
            }
        </ul>
    </div>

    <div class="content text-center default-icon-color">
        <div class="vertical-centering default-icon-color">
            <i class="fas fa-info-circle fa-7x"></i>
            <div class="no-records-message">The tenant was not found or you are not authorized.</div>
            <div class="no-records-message-detail">Please contact your tenant administrator if you are sure that the tenant should exist.</div>
        </div>
    </div>
</div>
