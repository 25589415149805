<ng-template>
    <div class="help-modal__container" [attr.aria-labelledby]="'modal-' + title" role="dialog">
        @if (showing) {
            <div class="{{ className }}" [@enterAnimation]>
                <div>
                    <h3 id="modal-{{ title }}" class="title__text">{{ title }}</h3>
                </div>
                <div class="help-modal__body">
                    @if (!labels.length) {
                        <span>
                            {{ emptyMessage }}
                        </span>
                    }
                    <div>
                        @for (label of labels; track label) {
                            <ul class="help-modal__list">
                                <h4 class="item-group-label">{{ label }}</h4>
                                @for (shortcut of shortcuts[label]; track shortcut; let i = $index) {
                                    <ng-keyboard-shortcuts-help-item [shortcut]="shortcut" [index]="i"></ng-keyboard-shortcuts-help-item>
                                }
                            </ul>
                        }
                    </div>
                </div>
            </div>
        }
        @if (showing) {
            <div class="help-modal__backdrop" [@overlayAnimation] (mousedown)="hide()"></div>
        }
    </div>
</ng-template>
