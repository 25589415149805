import { TenantNotificationItemsResult } from '@api';

export interface INotificationsStateModel {
    notifications: TenantNotificationItemsResult;
}

export const notificationsStateModelDefault = {
    name: 'notifications',
    defaults: {
        notifications: null as TenantNotificationItemsResult,
    },
};
