import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ItemsResult } from 'src/app/shared/interfaces/ItemsResult.model';

@Component({
    selector: 'app-standard-pagination',
    templateUrl: './standard-pagination.component.html',
    styleUrls: ['./standard-pagination.component.scss'],
})
export class StandardPaginationComponent {
    @Input() public paginationDetails: string;
    @Input() public data: ItemsResult<unknown>;
    @Output() public pageChange = new EventEmitter<number>();

    public emitPageChange(page: number) {
        this.pageChange.emit(page);
    }
}
