import { Injectable } from '@angular/core';
import { NotificationsService } from '@api';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { GetNotifications } from './get-notifications/get-notifications.actions';
import { getNotifications } from './get-notifications/get-notifications.handlers';
import { INotificationsStateModel, notificationsStateModelDefault } from './notifications.state.model';
import { PushNotifications } from './push-notifications/push-notifications.actions';
import { pushNotifications } from './push-notifications/push-notifications.handlers';
import { ReadAllNotifications } from './read-all-notifications/read-all-notifications.actions';
import { readAllNotifications } from './read-all-notifications/read-all-notifications.handlers';
import { ReadNotification } from './read-notification/read-notification.actions';
import { readNotification } from './read-notification/read-notification.handlers';

@State<INotificationsStateModel>(notificationsStateModelDefault)
@Injectable()
export class NotificationsState {
    constructor(
        private notificationsService: NotificationsService,
        private store: Store
    ) {}

    @Action(GetNotifications)
    public getNotifications(stateContext: StateContext<INotificationsStateModel>, action: GetNotifications) {
        return getNotifications(stateContext, action, this.store, this.notificationsService);
    }

    @Action(ReadNotification)
    public readNotification(stateContext: StateContext<INotificationsStateModel>, action: ReadNotification) {
        return readNotification(stateContext, action, this.store, this.notificationsService);
    }

    @Action(PushNotifications)
    public pushNotifications(stateContext: StateContext<INotificationsStateModel>, action: PushNotifications) {
        return pushNotifications(stateContext, action);
    }

    @Action(ReadAllNotifications)
    public readAllNotifications(stateContext: StateContext<INotificationsStateModel>, action: ReadAllNotifications) {
        return readAllNotifications(stateContext, action, this.store, this.notificationsService);
    }
}
