<div class="notifications-filters-container">
    <form [formGroup]="form" class="notifications-filter-form">
        <div class="d-flex align-items-center ps-2 pb-3">
            <div class="col-lg-3 p-0 me-2">
                <ng-select (change)="onFilterChanged()" [multiple]="true" [items]="notificationSeverity" bindLabel="name" bindValue="id" placeholder="Severity" formControlName="notificationSeverity">
                    <ng-template ng-option-tmp let-item="item">
                        <div [ngbTooltip]="item.name" placement="auto" openDelay="1000" class="text-truncate">{{ item.name }}</div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-lg-5 p-0 me-2">
                <ng-select (change)="onFilterChanged()" [multiple]="true" [items]="notificationType" bindLabel="name" bindValue="id" placeholder="Type" formControlName="notificationType">
                    <ng-template ng-option-tmp let-item="item">
                        <div [ngbTooltip]="item.name" placement="auto" openDelay="1000" class="text-truncate">{{ item.name }}</div>
                    </ng-template>
                </ng-select>
            </div>

            <div class="form-check mt-3">
                <input id="flexCheckChecked" type="checkbox" class="form-check-input" formControlName="notificationOwner" (change)="onFilterChanged()" />
                <label class="form-check-label" for="flexCheckChecked">Show only my notifications</label>
            </div>
        </div>
    </form>
</div>
