import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { KeyboardShortcutsHelpItemComponent } from './ng-keyboard-shortcuts-help-item.component';
import { KeyboardShortcutsHelpComponent } from './ng-keyboard-shortcuts-help.component';
import { KeyboardShortcutsHelpService } from './ng-keyboard-shortcuts-help.service';
import { KeyboardShortcutsSelectService } from './ng-keyboard-shortcuts-select.service';
import { KeyboardShortcutsComponent } from './ng-keyboard-shortcuts.component';
import { KeyboardShortcutsDirective } from './ng-keyboard-shortcuts.directive';
import { KeyboardShortcutsService } from './ng-keyboard-shortcuts.service';

@NgModule({
    imports: [CommonModule],
    declarations: [KeyboardShortcutsComponent, KeyboardShortcutsDirective, KeyboardShortcutsHelpComponent, KeyboardShortcutsHelpItemComponent],
    exports: [KeyboardShortcutsComponent, KeyboardShortcutsDirective, KeyboardShortcutsHelpComponent],
})
export class KeyboardShortcutsModuleStego {
    public static forRoot(): ModuleWithProviders<KeyboardShortcutsModuleStego> {
        return {
            ngModule: KeyboardShortcutsModuleStego,
            providers: [KeyboardShortcutsService, KeyboardShortcutsHelpService, KeyboardShortcutsSelectService],
        };
    }
}
