import { Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { StandardToolboxMode } from './standard-toolbox-mode';
import { StandardToolboxRef } from './standard-toolbox-ref';

@Component({
    selector: 'app-standard-toolbox',
    templateUrl: './standard-toolbox.component.html',
    styleUrls: ['./standard-toolbox.component.scss'],
})
export class StandardToolboxComponent implements OnDestroy {
    public title: string;
    public displayCloseButton = true;
    public mode: StandardToolboxMode = StandardToolboxMode.Over;
    public toolboxModes = StandardToolboxMode;

    @ViewChild('toolboxContainer', { static: true, read: ViewContainerRef }) public toolboxContainer: ViewContainerRef;
    constructor(private toolboxRef: StandardToolboxRef) {}

    public ngOnDestroy() {
        this.toolboxRef.close();
    }

    public onClose() {
        this.toolboxRef.close('cancel');
    }
}
