import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-device-status',
    templateUrl: './device-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceStatusComponent {
    @Input() public status: number;
}
