import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'localDate',
    standalone: true,
})
export class LocalDatePipe implements PipeTransform {
    public transform(value: string, format: string = null): string {
        if (value != null) {
            const dateFormat = format?.length ? format : 'YYYY-MM-DD HH:mm:ss';
            return moment(value).format(dateFormat);
        }
    }
}
