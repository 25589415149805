import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { IToastButton } from './toast-button';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-custom-toast]',
    templateUrl: './custom-toast.component.html',
    animations: [
        trigger('flyInOut', [
            state(
                'inactive',
                style({
                    display: 'none',
                    opacity: 0,
                })
            ),
            transition(
                'inactive => active',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            opacity: 0,
                        }),
                        style({
                            opacity: 1,
                        }),
                    ])
                )
            ),
            transition(
                'active => removed',
                animate(
                    '400ms ease-out',
                    keyframes([
                        style({
                            opacity: 1,
                        }),
                        style({
                            transform: 'translate3d(10%, 0, 0) skewX(10deg)',
                            opacity: 0,
                        }),
                    ])
                )
            ),
        ]),
    ],
    preserveWhitespaces: false,
})
export class CustomToastComponent extends Toast {
    public buttons: IToastButton[] = [];

    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage);
    }

    public action(btn: IToastButton) {
        this.toastPackage.triggerAction(btn);
        this.toastrService.clear();
        return false;
    }

    public trackByFn(index: number, _: IToastButton) {
        return index;
    }
}
