import { StateContext } from '@ngxs/store';
import { mergeItemsResultByTimestamp } from 'src/app/shared/extensions/pagination.extensions';
import { INotificationsStateModel } from '../notifications.state.model';
import { PushNotifications } from './push-notifications.actions';

export const pushNotifications = ({ getState, patchState }: StateContext<INotificationsStateModel>, action: PushNotifications) => {
    const state = getState();

    if (action.notifications.length === 0) {
        return;
    }

    patchState({
        notifications: mergeItemsResultByTimestamp(state.notifications, action.notifications),
    });
};
