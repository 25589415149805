import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { forEachChild } from 'typescript';

@Directive({
    selector: '[appNgbDropdownOnHover]',
})
export class NgbDropdownOnHoverDirective {
    @Input() public dropdowns: NgbDropdown[];
    @Input() public isExpanded: boolean;

    @HostListener('mouseenter')
    public onMouseEnter() {
        for (let d of this.dropdowns) {
            if (d !== this.dropdown && d.isOpen()) {
                d.close();
            }
        }

        if (!this.isExpanded) {
            this.dropdown.open();
        }
    }

    @HostListener('click')
    public onClick() {
        this.dropdown.close();
    }

    constructor(public dropdown: NgbDropdown) {
        dropdown.autoClose = true;
    }
}
