import { EventEmitter, InjectionToken } from '@angular/core';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const ToolboxData = new InjectionToken<any>('CONNECT_TOOLBOX_DATA');

export class StandardToolboxRef {
    public closeToolbox = new EventEmitter<object | string>();
    private isClosed = false;

    public close(result?: object | string) {
        if (!this.isClosed) {
            this.isClosed = true;
            this.closeToolbox.emit(result);
        }
    }
}
